import React, {
  FunctionComponent,
  HTMLProps,
  ReactElement,
  ReactNode,
} from 'react';
import styles from './ABIButton.module.scss';

type HTMLButtonType = 'button' | 'submit' | 'reset';

interface ABIButtonProps extends HTMLProps<HTMLButtonElement> {
  type?: HTMLButtonType;
  children: ReactNode;
}

const ABIButton: FunctionComponent<ABIButtonProps> = ({
  children,
  className,
  ...props
}): ReactElement => (
  <button
    type="button"
    {...props}
    className={`${styles.abiButton} ${className || ''}`}
  >
    {children}
  </button>
);

export default ABIButton;
