import { Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';
import abiLogo from '../../assets/images/logo-abi.svg';
import logo from '../../assets/images/logo.svg';
import { URL_SUPPORT } from '../../config/environment';
import { TranslationKey } from '../../i18n/translations';
import LanguageSwitcher from '../LanguageSwitcher';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import PasswordRecovery from './PasswordRecovery';
import styles from './Session.module.scss';

const Session = (): ReactElement => (
  <Grid
    container
    direction="row"
    justify="flex-end"
    alignItems="center"
    className={styles.container}
  >
    <Grid
      container
      item
      xs={12}
      sm={6}
      md={5}
      className={styles.panel}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Link to="/session/login" tabIndex={-1} className={styles.appLogoLink}>
        <img className={styles.appLogo} src={logo} alt="Exit Interview Logo" />
      </Link>

      <div className={styles.formContainer}>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="password-recovery" element={<PasswordRecovery />} />
        </Routes>

        <div className={styles.languageSwitcher}>
          <LanguageSwitcher />

          <a
            href={URL_SUPPORT}
            target="_blank"
            rel="noreferrer"
            className={styles.helpLink}
          >
            <FormattedMessage id={TranslationKey.REQUEST_HELP} />
          </a>
        </div>
      </div>

      <img src={abiLogo} alt="AbInBev Logo" />
    </Grid>
  </Grid>
);

export default Session;
