import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { TranslationKey } from '../../i18n/translations';
import styles from './Footer.module.scss';

const Footer = (): ReactElement => (
  <footer className={styles.footer}>
    <div className={styles.links}>
      <a href="src" target="_blank" rel="noopener noreferrer">
        <FormattedMessage id={TranslationKey.TERMS_AND_CONDITIONS} />
      </a>{' '}
      ·{' '}
      <a href="src" target="_blank" rel="noopener noreferrer">
        <FormattedMessage id={TranslationKey.PRIVACY_POLICY} />
      </a>{' '}
      ·{' '}
      <a href="src" target="_blank" rel="noopener noreferrer">
        <FormattedMessage
          id={TranslationKey.COPYRIGHT}
          values={{
            year: new Date().getFullYear(),
          }}
        />
      </a>
    </div>
  </footer>
);
export default Footer;
