import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Add as AddIcon, Search as SearchIcon } from '@material-ui/icons';
import React, {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { historyActions } from '../../actions';
import logoABI from '../../assets/images/logo-abi.svg';
import logoSeparator from '../../assets/images/logo-separator.svg';
import logo from '../../assets/images/logo.svg';
import ABIButton from '../../components/ABIButton';
import { URL_SUPPORT } from '../../config/environment';
import { UserRole } from '../../enums';
import { useWindowWidth } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import { userSessionSelectors } from '../../selectors';
import LanguageSwitcher from '../LanguageSwitcher';
import UserProfile from '../UserProfile/UserProfile';
import styles from './Menu.module.scss';

const useFormStyle = makeStyles(() => ({
  root: {
    marginTop: 0,
  },
}));

const BUTTON_TEXT_BREAKPOINT = 1100;

interface MenuProps {
  contentRef: RefObject<HTMLDivElement>;
}

const Menu: FunctionComponent<MenuProps> = ({ contentRef }): ReactElement => {
  const location = useLocation();
  const intl = useIntl();

  const user = useSelector(userSessionSelectors.getUser);

  const [scroll, setScroll] = useState(window.scrollY);

  useEffect(() => {
    if (!contentRef.current) return undefined;
    const contentElement = contentRef.current;
    const subscription = fromEvent(contentElement, 'scroll')
      .pipe(
        throttleTime(100, undefined, {
          leading: true,
          trailing: true,
        }),
      )
      .subscribe(() => {
        setScroll(contentElement.scrollTop);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [contentRef]);

  const [search, setSearch] = useState('');

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    [],
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        dispatch(
          historyActions.setFilters({
            nameOrId: search,
          }),
        );
        navigate('/history');
        dispatch(historyActions.initializeHistory());
        (event.target as HTMLElement).blur();
      } else if (event.key === 'Escape') {
        setSearch('');
      }
    },
    [dispatch, navigate, search],
  );

  const handleBlur = useCallback(() => {
    setSearch('');
  }, []);

  const abiLogoRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    if (!abiLogoRef.current) return undefined;

    const subscription = fromEvent(
      abiLogoRef.current,
      'transitionend',
    ).subscribe(() => {
      if (!abiLogoRef.current) return;
      abiLogoRef.current.classList[scroll ? 'add' : 'remove'](
        styles.logoHidden,
      );
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [scroll]);

  const formClasses = useFormStyle();
  const windowWidth = useWindowWidth();

  return (
    <div className={styles.container}>
      <div
        className={`${styles.logoContainer} ${
          scroll === 0 ? '' : styles.logoSide
        }`}
      >
        <Link to={user ? '/' : '/session/login'}>
          <img
            src={logo}
            alt="Logo Exit Interview"
            className={styles.logoExit}
          />
        </Link>

        <img src={logoSeparator} alt="Logo Separator" />

        <img
          ref={abiLogoRef}
          src={logoABI}
          alt="AbInBev Logo"
          className={styles.logoABI}
        />
      </div>

      {!location.pathname.startsWith('/external') && (
        <>
          <nav className={styles.navbar}>
            <ul>
              <li
                className={
                  location.pathname === '/history/pending'
                    ? styles.menuSelected
                    : ''
                }
              >
                <Link to="/history/pending" className={styles.menuLink}>
                  <FormattedMessage id={TranslationKey.PENDING_INTERVIEWS} />
                </Link>
              </li>

              <li
                className={
                  location.pathname === '/history' ? styles.menuSelected : ''
                }
              >
                <Link to="/history" className={styles.menuLink}>
                  <FormattedMessage id={TranslationKey.HISTORY} />
                </Link>
              </li>

              {user?.role === UserRole.Admin && (
                <li
                  className={
                    location.pathname.startsWith('/admin')
                      ? styles.menuSelected
                      : ''
                  }
                >
                  <Link to="/admin" className={styles.menuLink}>
                    <FormattedMessage id={TranslationKey.ADMINISTRATION} />
                  </Link>
                </li>
              )}

              <li
                className={
                  location.pathname === '/reports' ? styles.menuSelected : ''
                }
              >
                <Link to="/reports" className={styles.menuLink}>
                  <FormattedMessage id={TranslationKey.REPORTS} />
                </Link>
              </li>

              <li
                className={
                  location.pathname === '/word-cloud' ? styles.menuSelected : ''
                }
              >
                <Link to="/word-cloud" className={styles.menuLink}>
                  <FormattedMessage id={TranslationKey.WORD_CLOUD} />
                </Link>
              </li>
              {user?.dh_access && (
                <li
                  className={
                    location.pathname === '/boards' ? styles.menuSelected : ''
                  }
                >
                  <Link to="/boards" className={styles.menuLink}>
                    <FormattedMessage id={TranslationKey.BOARD} />
                  </Link>
                </li>
              )}

              <li>
                <a
                  href={URL_SUPPORT}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.menuLink}
                >
                  <FormattedMessage id={TranslationKey.HELP} />
                </a>
              </li>
            </ul>
          </nav>

          <div className={styles.actionsContainer}>
            <TextField
              className={styles.search}
              value={search}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              placeholder={intl.formatMessage({
                id: TranslationKey.NAME_OR_ID,
              })}
              size="small"
              variant="outlined"
              classes={formClasses}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={styles.searchIcon}
                  >
                    <SearchIcon color="inherit" />
                  </InputAdornment>
                ),
              }}
            />

            <Link to="/interview/new">
              <ABIButton type="button" className={styles.mainAction}>
                {windowWidth >= BUTTON_TEXT_BREAKPOINT ? (
                  <FormattedMessage id={TranslationKey.APPLY_INTERVIEW} />
                ) : (
                  <AddIcon color="inherit" />
                )}
              </ABIButton>
            </Link>

            <UserProfile />

            <div className={styles.lang}>
              <LanguageSwitcher />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Menu;
