import { CircularProgress, Grid } from '@material-ui/core';
import emojiFlags from 'emoji-flags';
import { Form, Formik } from 'formik';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { wordCloudActions } from '../../actions';
import {
  FormDateField,
  FormRadioField,
  FormSelectField,
} from '../../components/FormFields';
import MainButton from '../../components/MainButton';
import { bigBUs, smallBUs } from '../../constants';
import {
  BusinessUnitCode,
  EExitType,
  QuestionaryFieldPrefix,
  QuestionaryType,
  UserRole,
  VicePresidencyCode,
  WordCloudFilterField,
} from '../../enums';
import { useFetchQuestions } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import { countriesWithZone } from '../../maps';
import { userSessionSelectors, wordCloudSelectors } from '../../selectors';
import isoCountries from '../../services/isoCountries';
import { SelectOption, WordCloudFilterValues } from '../../types';
import TranslateFormikErrors from '../TranslateFormikErrors';
import FormValueSync from './FormValueSync';
import OwnerBigFilter from './OwnerBigFilter';
import OwnerSmallFilter from './OwnerSmallFilter';
import RegularFilter from './RegularFilter';
import { getChartValidationSchema } from './schemas';
import SuperAdminFilter from './SuperAdminFilter';
import styles from './WordCloud.module.scss';

const initialValues: WordCloudFilterValues = {
  [WordCloudFilterField.Question]: '',
  [WordCloudFilterField.ExitType]: '',
  [WordCloudFilterField.From]: '',
  [WordCloudFilterField.Until]: '',
  [WordCloudFilterField.AllZone]: false,
  [WordCloudFilterField.BusinessUnits]: [],
  [WordCloudFilterField.AllBU]: false,
  [WordCloudFilterField.Countries]: [],
  [WordCloudFilterField.VicePresidencies]: [],
  [WordCloudFilterField.UENs]: [],
};

const WordCloudFilters = (): ReactElement => {
  const intl = useIntl();

  const userRole = useSelector(userSessionSelectors.getRole);
  const userBU = useSelector(userSessionSelectors.getUserBU);

  const [fetchingQuestions, questions] = useFetchQuestions(
    QuestionaryType.Interview,
    intl,
  );
  const mappedQuestions = useMemo(
    (): SelectOption[] =>
      questions
        .filter(
          ({ fieldPrefix }) =>
            ![
              QuestionaryFieldPrefix.Email,
              QuestionaryFieldPrefix.PhoneNumber,
            ].includes(fieldPrefix as QuestionaryFieldPrefix),
        )
        .map(({ fieldPrefix, question }) => ({
          value: fieldPrefix,
          label: question,
        })),
    [questions],
  );

  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    (values: WordCloudFilterValues) => {
      dispatch(wordCloudActions.setFetching(true));
      dispatch(
        wordCloudActions.setWordCloudConfig({
          from: values[WordCloudFilterField.From] as Date,
          until: (values[WordCloudFilterField.Until] as Date) || undefined,
          fieldPrefix: values[
            WordCloudFilterField.Question
          ] as QuestionaryFieldPrefix,
          exitType: values[WordCloudFilterField.ExitType] as EExitType,
        }),
      );
      dispatch(
        wordCloudActions.fetchWordCloudData({
          ...values,
          [WordCloudFilterField.Until]:
            values[WordCloudFilterField.Until] || new Date(),
        }),
      );
    },
    [dispatch],
  );

  const validationSchema = useMemo(
    () => getChartValidationSchema(intl, userRole, userBU),
    [intl, userRole, userBU],
  );

  const exitTypeOptions = useMemo(
    () =>
      Object.values(EExitType).map((value) => ({
        value,
        label: intl.formatMessage({
          id: value,
        }),
      })),
    [intl],
  );

  const businessUnitOptions = useMemo(
    () =>
      Object.values(BusinessUnitCode).map((code) => ({
        value: code,
        label: intl.formatMessage({
          id: `BUSINESS_UNIT_${code}`,
        }),
      })),
    [intl],
  );

  const countryOptions = useMemo(
    () =>
      countriesWithZone
        .filter(({ businessUnit }) =>
          [BusinessUnitCode.CAC, BusinessUnitCode.HondurasAndSalvador].includes(
            businessUnit,
          ),
        )
        .map(({ country }) => ({
          value: country,
          label: `${
            emojiFlags.countryCode(country)?.emoji || ''
          } ${isoCountries.getName(country, intl.locale)}`,
        })),
    [intl.locale],
  );

  const vicePresidencyOptions = useMemo(
    () =>
      Object.values(VicePresidencyCode).map((vpCode) => ({
        value: vpCode,
        label: intl.formatMessage({
          id: `VP_${vpCode}`,
        }),
      })),
    [intl],
  );

  const fetching = useSelector(wordCloudSelectors.getFetching);

  return (
    <div className={styles.filterCard}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Form noValidate autoComplete="off">
          <TranslateFormikErrors />
          <FormValueSync />

          <h3 className={`${styles.filterStep} ${styles.required}`}>
            1. <FormattedMessage id={TranslationKey.EXIT_TYPE} />
          </h3>

          <hr className={styles.separator} />

          <FormRadioField
            formClassName={styles.radioForm}
            name={WordCloudFilterField.ExitType}
            options={exitTypeOptions}
            disabled={fetching}
          />

          <h3 className={`${styles.filterStep} ${styles.required}`}>
            2. <FormattedMessage id={TranslationKey.QUESTION} />
          </h3>

          <hr className={styles.separator} />

          <FormSelectField
            name={WordCloudFilterField.Question}
            label={intl.formatMessage({
              id: TranslationKey.QUESTION,
            })}
            options={mappedQuestions}
            disabled={fetching || fetchingQuestions}
            loading={fetchingQuestions}
          />

          <hr className={`${styles.separator} ${styles.complete}`} />

          <h3 className={`${styles.filterStep} ${styles.required}`}>
            3. <FormattedMessage id={TranslationKey.PERIOD} />
          </h3>

          <hr className={styles.separator} />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormDateField
                name={WordCloudFilterField.From}
                label={intl.formatMessage({
                  id: TranslationKey.FROM,
                })}
                maxDate={new Date()}
                disabled={fetching}
              />
            </Grid>

            <Grid item xs={6}>
              <FormDateField
                name={WordCloudFilterField.Until}
                label={intl.formatMessage({
                  id: TranslationKey.UNTIL,
                })}
                maxDate={new Date()}
                disabled={fetching}
              />
            </Grid>
          </Grid>

          <hr className={`${styles.separator} ${styles.complete}`} />

          {userRole === UserRole.Admin && (
            <SuperAdminFilter
              businessUnitOptions={businessUnitOptions}
              vicePresidencyOptions={vicePresidencyOptions}
            />
          )}

          {[UserRole.Regular, UserRole.CountryOwner].includes(userRole) && (
            <RegularFilter vicePresidencyOptions={vicePresidencyOptions} />
          )}

          {bigBUs.includes(userBU) && userRole === UserRole.Owner && (
            <OwnerBigFilter vicePresidencyOptions={vicePresidencyOptions} />
          )}

          {smallBUs.includes(userBU) && userRole === UserRole.Owner && (
            <OwnerSmallFilter
              vicePresidencyOptions={vicePresidencyOptions}
              countryOptions={countryOptions}
            />
          )}

          <hr className={`${styles.separator} ${styles.complete}`} />

          <div className={styles.buttonContainer}>
            <MainButton
              type="submit"
              appButtonType="download"
              appColorType="secondary"
              disabled={fetching}
            >
              {fetching && (
                <CircularProgress
                  size="14px"
                  color="inherit"
                  thickness={6}
                  className={styles.buttonSpinner}
                />
              )}
              <FormattedMessage
                id={
                  fetching
                    ? TranslationKey.GENERATING
                    : TranslationKey.GENERATE_CHART
                }
              />
            </MainButton>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default WordCloudFilters;
