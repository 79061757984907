import { Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import preFeedbackImage from '../../../assets/images/feedback.png';
import { TranslationKey } from '../../../i18n/translations';
import ContentTitle from '../../ContentTitle';
import MainButton from '../../MainButton';
import StatusBar from '../../StatusBar';
import styles from './PreFeedback.module.scss';

const PreFeedback = (): ReactElement => (
  <div className={styles.container}>
    <StatusBar
      statusText={<FormattedMessage id={TranslationKey.PRE_FEEDBACK_STATUS} />}
    />

    <Grid container>
      <Grid item xs={12}>
        <ContentTitle>
          <FormattedMessage id={TranslationKey.FEEDBACK_TITLE} />
        </ContentTitle>
      </Grid>

      <Grid item sm={12} md={6} className={styles.text}>
        <p>
          <FormattedMessage id={TranslationKey.PRE_FEEDBACK_TEXT_1} />
        </p>

        <p>
          <FormattedMessage id={TranslationKey.PRE_FEEDBACK_TEXT_2} />
        </p>
      </Grid>

      <Grid item sm={12} md={6} className={styles.imageContainer}>
        <img
          src={preFeedbackImage}
          alt="People having conversation"
          className={styles.image}
        />
      </Grid>
    </Grid>

    <Grid container direction="column" justify="center" alignItems="center">
      <Grid item>
        <p className={styles.whatNext}>
          <FormattedMessage id={TranslationKey.PRE_FEEDBACK_QUESTION} />
        </p>
      </Grid>

      <Grid item className={styles.buttonsContainer}>
        <Link to="/">
          <MainButton type="button" appColorType="secondary">
            <FormattedMessage id={TranslationKey.EXIT} />
          </MainButton>
        </Link>

        <Link to="/interview/feedback">
          <MainButton type="button">
            <FormattedMessage id={TranslationKey.PRE_FEEDBACK_CONTINUE} />
          </MainButton>
        </Link>
      </Grid>
    </Grid>

    {/* <div className={styles.buttonsContainer}>
    </div> */}
  </div>
);

export default PreFeedback;
