import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons';
import { snakeCase } from 'lodash-es';
import React, {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { historyActions, interviewActions } from '../../actions';
import ContentTitle from '../../components/ContentTitle';
import LoadingSpinner from '../../components/LoadingSpinner';
import {
  Breakpoint,
  HistoryInterviewField,
  InterviewStatus,
  SortDirection,
} from '../../enums';
import { useWindowWidth } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import { cursorStatusMap } from '../../maps';
import { historySelectors } from '../../selectors';
import { HistoryColumn, HistoryInterview } from '../../types';
import styles from './Pending.module.scss';

const colorStatusMap: Record<InterviewStatus, string> = {
  [InterviewStatus.Registered]: styles.red,
  [InterviewStatus.Interview]: styles.orange,
  [InterviewStatus.Feedback]: styles.orange,
  [InterviewStatus.FeedbackSent]: styles.red,
  [InterviewStatus.FeedbackAccepted]: styles.green,
  [InterviewStatus.FeedbackRejected]: styles.orange,
  [InterviewStatus.FeedbackNotConfirmed]: styles.green,
};

const Pending = (): ReactElement => {
  const dispatch = useDispatch();
  const page = useSelector(historySelectors.getPage);
  const sortColumn = useSelector(historySelectors.getSortColumn);
  const sortDirection = useSelector(historySelectors.getSortDirection);

  useEffect(() => {
    dispatch(historyActions.setOnlyPending(true));
    // Triggers fetch
    dispatch(historyActions.setPage(0));
    dispatch(historyActions.setSortColumn('createdAt'));
    dispatch(historyActions.setSortDirection(SortDirection.Descending));
    return () => {
      dispatch(historyActions.clearState());
    };
  }, [dispatch]);

  const fetching = useSelector(historySelectors.getFetching);
  const pendingInterviews = useSelector(historySelectors.getPendingInterviews);
  const count = useSelector(historySelectors.getCount);

  const handleSortClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { column } = (event.currentTarget as HTMLButtonElement).dataset as {
        column: string;
      };
      let newSortDirection = sortDirection;
      if (column === sortColumn) {
        newSortDirection =
          sortDirection === SortDirection.Ascending
            ? SortDirection.Descending
            : SortDirection.Ascending;
      } else {
        newSortDirection = sortDirection;
      }

      dispatch(historyActions.setSortColumn(column));
      dispatch(historyActions.setSortDirection(newSortDirection));
    },
    [dispatch, sortColumn, sortDirection],
  );

  const navigate = useNavigate();
  const handleRowClick = useCallback(
    (event: MouseEvent<HTMLTableRowElement>) => {
      const { id, interviewerId, employee, status } = event.currentTarget
        .dataset as {
        id: string;
        interviewerId: string;
        employee: string;
        status: InterviewStatus;
      };

      if (status === InterviewStatus.FeedbackSent) {
        return;
      }

      dispatch(
        interviewActions.setInterviewValues(
          Number(id),
          Number(interviewerId),
          employee,
          status,
        ),
      );

      navigate(
        `/interview/${
          status === InterviewStatus.Interview ? 'instructions' : 'pre-feedback'
        }`,
      );
    },
    [dispatch, navigate],
  );

  const handlePageClick = useCallback(
    (_event, newPage: number) => {
      dispatch(historyActions.setPage(newPage));
    },
    [dispatch],
  );

  const windowWidth = useWindowWidth();
  const showMobile = windowWidth < Breakpoint.Medium;

  const pendingColumns = useMemo(
    (): HistoryColumn[] =>
      [
        {
          field: HistoryInterviewField.Sharp,
        },
        {
          field: HistoryInterviewField.Employee,
          mobile: true,
          render: (value: string, interview: HistoryInterview) =>
            showMobile ? (
              <>
                <div>
                  {interview[HistoryInterviewField.Sharp]}{' '}
                  {interview[HistoryInterviewField.IsCritical] && (
                    <span className={styles.criticIcon}>
                      <ErrorOutlineIcon color="inherit" />
                    </span>
                  )}
                </div>
                <div className={styles.employeeText}>{value}</div>
              </>
            ) : (
              <>
                {value}{' '}
                {interview[HistoryInterviewField.IsCritical] && (
                  <span className={styles.criticIcon}>
                    <ErrorOutlineIcon color="inherit" />
                  </span>
                )}
              </>
            ),
        },
        {
          field: HistoryInterviewField.LineManager,
        },
        {
          field: HistoryInterviewField.CreatedAt,
          render: (value: Date) => <FormattedDate value={value} />,
        },
        {
          field: HistoryInterviewField.UEN,
        },
        {
          field: HistoryInterviewField.Status,
          mobile: true,
          render: (value: InterviewStatus) => (
            <>
              <FormattedMessage id={`INTERVIEW_STATUS_${value}`} />{' '}
              <div className={`${styles.dot} ${colorStatusMap[value]}`} />
            </>
          ),
        },
      ].filter(({ mobile }) => (showMobile ? !!mobile : true)),
    [showMobile],
  );

  return (
    <div className={styles.container}>
      <Grid container>
        <Grid item xs={6} className={styles.textContainer}>
          <ContentTitle>
            <FormattedMessage id={TranslationKey.PENDING_INTERVIEWS} />
          </ContentTitle>
        </Grid>
        {fetching && (
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justify="center"
            direction="column"
            className={styles.spinner}
          >
            <LoadingSpinner />
          </Grid>
        )}
        {!fetching && !!pendingInterviews.length && (
          <>
            <Grid item xs={12} className={styles.tableContainer}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow className={styles.tableRow}>
                      {pendingColumns.map(({ field }) => (
                        <TableCell key={field} className={styles.tableCell}>
                          <TableSortLabel
                            active={field === sortColumn}
                            direction={sortDirection}
                            onClick={handleSortClick}
                            data-column={field}
                          >
                            <FormattedMessage
                              id={`HISTORY_COLUMN_${snakeCase(
                                field,
                              ).toUpperCase()}`}
                            />
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {pendingInterviews.map((interview) => (
                      <TableRow
                        key={interview.id}
                        onClick={handleRowClick}
                        data-id={interview.id}
                        data-interviewer-id={interview.interviewerId}
                        data-employee={interview.employee}
                        data-status={interview.status}
                        style={{
                          cursor: cursorStatusMap[interview.status],
                          height: 43,
                        }}
                      >
                        {pendingColumns.map(({ field, render }, index) => (
                          <TableCell
                            key={field}
                            padding="none"
                            style={{
                              paddingLeft: index === 0 ? 15 : undefined,
                            }}
                          >
                            {render
                              ? render(interview[field], interview)
                              : interview[field]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <TablePagination
                  component="div"
                  count={count}
                  rowsPerPage={10}
                  page={page}
                  onPageChange={handlePageClick}
                  labelRowsPerPage=""
                  labelDisplayedRows={({ from, to, ...rest }) => (
                    <FormattedMessage
                      id={TranslationKey.HISTORY_ROW_DISPLAY}
                      values={{
                        from,
                        to,
                        count: rest.count,
                      }}
                    />
                  )}
                  classes={{
                    select: styles.rowSelect,
                    selectIcon: styles.rowSelect,
                  }}
                />
              </TableContainer>
            </Grid>
          </>
        )}

        {!fetching && !pendingInterviews.length && (
          <Grid
            container
            item
            xs={12}
            direction="column"
            justify="center"
            alignItems="center"
            className={styles.noPending}
          >
            <p>
              <FormattedMessage id={TranslationKey.NO_PENDING_INTERVIEWS} />
            </p>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Pending;
