import { IconButton, Menu, MenuItem } from '@material-ui/core';
import {
  AccountCircle as AccountCircleIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  ExitToApp as ExitToAppIcon,
  HelpOutline as HelpOutlineIcon,
  Lock as LockIcon,
  Search as SearchIcon,
  Translate as TranslateIcon,
} from '@material-ui/icons';
import React, { MouseEvent, ReactElement, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { userSessionActions } from '../../actions';
import logoABI from '../../assets/images/logo-abi.svg';
import logoSeparator from '../../assets/images/logo-separator.svg';
import logo from '../../assets/images/logo.svg';
import { Locale } from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { userSessionSelectors } from '../../selectors';
import styles from './MobileMenu.module.scss';

const MobileMenu = (): ReactElement => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  const hasUser = !!useSelector(userSessionSelectors.getUser);

  const handleHelpClick = useCallback(() => {
    navigate('/help');
  }, [navigate]);

  const handleSearchClick = useCallback(() => {
    navigate('/history');
  }, [navigate]);

  const handleNewInterviewClick = useCallback(() => {
    navigate('/interview/new');
  }, [navigate]);

  const handleTranslateClick = useCallback(() => {
    const newLocale =
      intl.locale === Locale.English ? Locale.Spanish : Locale.English;
    dispatch(userSessionActions.setLocale(newLocale));
  }, [dispatch, intl.locale]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMenuOpenClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      const { link, closeSession } = event.currentTarget.dataset as {
        link: string;
        closeSession: string;
      };
      if (closeSession) {
        dispatch(userSessionActions.logoutUserRequest());
        return;
      }
      if (link) navigate(link);
      setAnchorEl(null);
    },
    [dispatch, navigate],
  );

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <Link to={hasUser ? '/' : '/session/login'}>
          <img
            src={logo}
            alt="Logo Exit Interview"
            className={styles.logoExit}
          />
        </Link>
        <img
          src={logoSeparator}
          alt="Logo Separator"
          className={styles.logoSeparator}
        />
        <img src={logoABI} alt="AbInBev Logo" className={styles.logoABI} />
      </div>

      <div className={styles.menuBottom}>
        <IconButton className={styles.menuButton} onClick={handleHelpClick}>
          <HelpOutlineIcon color="inherit" />
        </IconButton>

        <div className={styles.buttonsContainer}>
          <IconButton className={styles.menuButton} onClick={handleSearchClick}>
            <SearchIcon color="inherit" />
          </IconButton>

          <IconButton
            className={styles.menuButton}
            onClick={handleNewInterviewClick}
          >
            <AddCircleOutlineIcon color="inherit" />
          </IconButton>

          <IconButton
            className={styles.menuButton}
            onClick={handleTranslateClick}
          >
            <TranslateIcon color="inherit" />
          </IconButton>

          <IconButton
            className={styles.menuButton}
            onClick={handleMenuOpenClick}
          >
            <AccountCircleIcon color="inherit" />
          </IconButton>

          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            open={!!anchorEl}
            onClose={handleMenuClose}
            keepMounted
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              className={styles.menuItem}
              onClick={handleMenuItemClick}
              data-link="/profile/password-change"
            >
              <LockIcon color="inherit" />{' '}
              <FormattedMessage id={TranslationKey.MY_PASSWORD} />
            </MenuItem>
            <MenuItem
              className={styles.menuItem}
              onClick={handleMenuItemClick}
              data-link="/session/login"
              data-close-session="true"
            >
              <ExitToAppIcon color="inherit" />{' '}
              <FormattedMessage id={TranslationKey.CLOSE_SESSION} />
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
