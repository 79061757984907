import { Avatar, IconButton, Menu, MenuItem } from '@material-ui/core';
import {
  ExitToApp as ExitToAppIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Lock as LockIcon,
} from '@material-ui/icons';
import React, { MouseEvent, ReactElement, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { userSessionActions } from '../../actions';
import { TranslationKey } from '../../i18n/translations';
import { userSessionSelectors } from '../../selectors';
import styles from './UserProfile.module.scss';

const UserProfile = (): ReactElement => {
  const user = useSelector(userSessionSelectors.getUser);
  const name = user?.name || '';
  const position = user?.position || '';
  // const avatarURL = '';

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMenuOpenClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleMenuItemClick = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      const { link, closeSession } = event.currentTarget.dataset as {
        link: string;
        closeSession: string;
      };
      if (closeSession) {
        dispatch(userSessionActions.logoutUserRequest());
        return;
      }
      if (link) navigate(link);
      setAnchorEl(null);
    },
    [dispatch, navigate],
  );

  return (
    <div className={styles.profile}>
      <Avatar alt={name}>
        {name
          .split(' ')
          .map((part) => part[0])
          .slice(0, 2)
          .join('')}
      </Avatar>

      <div className={styles.text}>
        <h3>{name}</h3>
        <small>{position}</small>
      </div>

      <IconButton className={styles.menuButton} onClick={handleMenuOpenClick}>
        <KeyboardArrowDownIcon color="inherit" />
      </IconButton>

      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={!!anchorEl}
        onClose={handleMenuClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          className={styles.menuItem}
          onClick={handleMenuItemClick}
          data-link="/profile/password-change"
        >
          <LockIcon color="inherit" />{' '}
          <FormattedMessage id={TranslationKey.MY_PASSWORD} />
        </MenuItem>

        <MenuItem
          className={styles.menuItem}
          onClick={handleMenuItemClick}
          data-link="/session/login"
          data-close-session="true"
        >
          <ExitToAppIcon color="inherit" />{' '}
          <FormattedMessage id={TranslationKey.CLOSE_SESSION} />
        </MenuItem>
      </Menu>

      <div className={styles.divider} />
    </div>
  );
};

export default UserProfile;
