import { Grid } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { reportsActions } from '../../actions';
import ContentTitle from '../../components/ContentTitle';
import { TranslationKey } from '../../i18n/translations';
import ReportChart from '../ReportChart';
import ChartFilters from './ChartFilters';
import ExcelFilters from './ExcelFilters';
import styles from './Reports.module.scss';

const Reports = (): ReactElement => {
  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch(reportsActions.clearState());
    },
    [dispatch],
  );

  return (
    <Grid
      container
      className={styles.container}
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid
        item
        sm={12}
        md={4}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        className={styles.filtersContainer}
      >
        <Grid item xs={12}>
          <ContentTitle>
            <FormattedMessage id={TranslationKey.REPORTS} />
          </ContentTitle>
        </Grid>

        <Grid item xs={12}>
          <p className={styles.instructions}>
            <FormattedMessage id={TranslationKey.REPORTS_INSTRUCTIONS} />
          </p>
        </Grid>

        <Grid item xs={12} className={styles.chartFilterContainer}>
          <ChartFilters />
        </Grid>
      </Grid>

      <Grid
        item
        sm={12}
        md={8}
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={styles.chartContainer}
      >
        <ReportChart />
      </Grid>

      <Grid
        item
        sm={12}
        md={4}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        className={styles.filtersContainer}
      >
        <Grid item xs={12} className={styles.chartFilterContainer}>
          <ExcelFilters />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Reports;
