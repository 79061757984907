import { VideoLibrary } from '@material-ui/icons';
import { startCase } from 'lodash-es';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReactComponent as InterviewIcon } from '../../assets/images/interview-icon.svg';
import { ReactComponent as ReportIcon } from '../../assets/images/report-icon.svg';
import wordCloudImg from '../../assets/images/word-cloud.svg';
import PictureLink from '../../components/PictureLink';
import { TranslationKey } from '../../i18n/translations';
import { userSessionSelectors } from '../../selectors';
import styles from './Home.module.scss';

const Home = (): ReactElement => {
  const user = useSelector(userSessionSelectors.getUser);
  const firstName = startCase(
    user?.name?.split(' ').slice(0, 2).join(' ') || '',
  );
  const tutorialsLink =
    'https://anheuserbuschinbev.sharepoint.com/sites/somos_MAZ/simplificaMAZ/SitePages/Exit-Interview.aspx';

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h1>
          <FormattedMessage
            id={TranslationKey.GREETING}
            values={{
              firstName,
            }}
          />
        </h1>

        <h2>
          <FormattedMessage id={TranslationKey.INIT_QUESTION} />
        </h2>
      </div>

      <div className={styles.content}>
        <div className={styles.buttonsContainer}>
          <PictureLink
            icon={<InterviewIcon />}
            label={<FormattedMessage id={TranslationKey.APPLY_INTERVIEW} />}
            to="/interview/new"
          />

          <hr />

          <PictureLink
            icon={<ReportIcon />}
            label={<FormattedMessage id={TranslationKey.VIEW_REPORT} />}
            to="/reports"
          />

          <hr />

          <PictureLink
            icon={
              <img
                className={styles.wordCloud}
                src={wordCloudImg}
                alt="Word cloud report."
              />
            }
            label={<FormattedMessage id={TranslationKey.WORD_CLOUD} />}
            to="/word-cloud"
          />

          <hr />

          <PictureLink
            icon={
              <span className={styles.tutorialIcon}>
                <VideoLibrary fontSize="inherit" />
              </span>
            }
            label={<FormattedMessage id={TranslationKey.VIEW_TUTORIALS} />}
            to={tutorialsLink}
            isExternal
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
