import { createTheme } from '@material-ui/core/styles';
import { AppColor } from './enums';

const theme = createTheme({
  palette: {
    primary: {
      main: AppColor.BrightOrange,
    },
    tonalOffset: 0.2,
  },
  overrides: {
    MuiFormControl: {
      root: {
        marginTop: 20,
      },
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          color: AppColor.Placeholder,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        pointerEvents: 'auto',
      },
      formControl: {
        top: -30,
        left: -8,
      },
      asterisk: {
        color: AppColor.Error,
      },
    },
    MuiSelect: {
      icon: {
        color: AppColor.BrightOrange,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        '& legend': {
          maxWidth: 0,
        },
      },
    },
    MuiMenuItem: {
      root: {
        color: AppColor.MenuGrey,
      },
    },
    MuiFormLabel: {
      root: {
        pointerEvents: 'auto',
      },
    },
  },
});

export default theme;
