import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  RefObject,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { fromEvent } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import doorIcon from '../../assets/images/icon-door.svg';
import { TranslationKey } from '../../i18n/translations';
import styles from './StatusBar.module.scss';

interface StatusBarProps {
  statusText: string | ReactNode;
  saving?: boolean;
  showExit?: boolean;
  contentRef?: RefObject<HTMLDivElement>;
}

const StatusBar: FunctionComponent<StatusBarProps> = ({
  statusText,
  saving = false,
  showExit = false,
  contentRef,
}): ReactElement => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const contentElement = contentRef?.current;
    if (!contentElement) return undefined;
    const subscription = fromEvent(contentElement, 'scroll')
      .pipe(
        throttleTime(100, undefined, {
          leading: true,
          trailing: true,
        }),
        map((event) => {
          const element = event.target as HTMLDivElement;
          return (
            (element.scrollTop /
              (element.scrollHeight - element.offsetHeight)) *
            100
          );
        }),
      )
      .subscribe((newProgress) => {
        setProgress(newProgress);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [contentRef]);

  return (
    <div className={styles.container}>
      <div />
      <div className={styles.textContainer}>
        <p>{statusText}</p>
        {saving && (
          <span>
            <FormattedMessage id={TranslationKey.SAVING} />…
          </span>
        )}
      </div>

      {saving && (
        <span className={styles.mobileSaving}>
          <FormattedMessage id={TranslationKey.SAVING} />…
        </span>
      )}

      <Link to="/" className={styles.exitLink}>
        {showExit && (
          <img src={doorIcon} alt="Door icon" className={styles.exitIcon} />
        )}
      </Link>

      <div
        className={styles.progressBar}
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  );
};

export default StatusBar;
