import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField } from 'formik';
import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { AppColor, HttpMethod } from '../../enums';
import { useDebounce } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import { logger } from '../../services';
import { IOption } from '../../types';

const useStyles = makeStyles(() => ({
  popupIndicator: {
    color: AppColor.BrightOrange,
  },
}));

interface AutocompleteFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  apiURL: string;
  onValueChange?: (option: IOption[] | IOption | null) => void;
  disabled?: boolean;
  required?: boolean;
  loading?: boolean;
  multiple?: boolean;
  queryProperty?: string;
}

const FormAutocompleteField: FunctionComponent<AutocompleteFieldProps> = ({
  name,
  label,
  placeholder,
  apiURL,
  onValueChange,
  disabled = false,
  required = false,
  loading = false,
  multiple = false,
  queryProperty = 'name',
}): ReactElement => {
  const [field, meta] = useField<IOption[] | IOption>(name);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const mountedRef = useRef(false);

  const debouncedValue = useDebounce(inputValue, 300);
  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      return undefined;
    }

    setFetchLoading(true);

    const urlWithQuery = `${apiURL}?${queryProperty}=${encodeURIComponent(
      debouncedValue,
    )}`;
    logger.info(`Autocompleting ${urlWithQuery}`);

    const subscription$ = ajax({
      url: urlWithQuery,
      method: HttpMethod.Get,
      headers: {
        Accept: 'application/json; charset=UTF-8',
      },
      withCredentials: true,
    })
      .pipe(map(({ response }: { response: IOption[] }) => response))
      .subscribe(
        (newOptions) => {
          setOptions(newOptions);
          setFetchLoading(false);
        },
        (err) => {
          logger.error(`Error autocompleting ${urlWithQuery}`, err);
          setFetchLoading(false);
        },
      );

    return () => {
      subscription$.unsubscribe();
    };
  }, [apiURL, queryProperty, debouncedValue]);

  const handleChange = useCallback(
    (_event, newValue: IOption[] | IOption | null) => {
      field.onChange({
        target: {
          name,
          value: newValue,
        },
      });
      if (onValueChange) onValueChange(newValue);
    },
    [field, name, onValueChange],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    },
    [],
  );

  /**
   * Sync changes from outside component (for single value)
   */
  const fieldValue = field.value;
  useEffect(() => {
    if (Array.isArray(fieldValue)) return;

    const hasValue = !!options.find(({ id }) => id === fieldValue?.id);
    if (!hasValue && !!fieldValue) {
      setOptions([...options, fieldValue]);
    }
  }, [fieldValue, options]);

  const classes = useStyles();
  const hasError = meta.touched && !!meta.error;

  return (
    <Autocomplete
      {...field}
      multiple={multiple}
      classes={classes}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, optionValue) =>
        option.name === optionValue.name
      }
      placeholder={placeholder || label}
      getOptionLabel={(option: IOption) => option?.name || ''}
      options={options}
      loading={fetchLoading}
      disabled={disabled}
      onChange={handleChange}
      loadingText={<FormattedMessage id={TranslationKey.LOADING} />}
      noOptionsText={
        inputValue ? (
          <FormattedMessage id={TranslationKey.AUTOCOMPLETE_NO_OPTIONS} />
        ) : (
          <FormattedMessage id={TranslationKey.AUTOCOMPLETE_INSTRUCTIONS} />
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder || label}
          variant="outlined"
          size="small"
          value={inputValue}
          required={required}
          onChange={handleInputChange}
          fullWidth
          InputLabelProps={{
            shrink: false,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {fetchLoading || loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          error={hasError}
          helperText={hasError ? meta.error : ' '}
        />
      )}
    />
  );
};

export default FormAutocompleteField;
