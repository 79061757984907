import {
  CircularProgress,
  InputAdornment,
  makeStyles,
  OutlinedTextFieldProps,
  TextField,
} from '@material-ui/core';
import { useField } from 'formik';
import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react';
import { AppColor } from '../../enums';

const useAdornmentStyle = makeStyles(() => ({
  root: {
    color: AppColor.Placeholder,
  },
}));

interface FormTextFieldProps extends Partial<OutlinedTextFieldProps> {
  name: string;
  label: string;
  type?: string;
  onValueChange?: (value?: string | number) => void;
  loading?: boolean;
  autoComplete?: 'off' | 'new-password';
  startElement?: ReactNode;
  endElement?: ReactNode;
}

const FormTextField: FunctionComponent<FormTextFieldProps> = ({
  name,
  label,
  placeholder,
  onValueChange,
  type = 'text',
  loading = false,
  autoComplete = 'off',
  startElement,
  endElement = null,
  ...props
}): ReactElement => {
  const [field, meta, { setValue }] = useField<number | string>(name);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (onValueChange) onValueChange(newValue);
      setValue(newValue);
    },
    [setValue, onValueChange],
  );

  const hasError = meta.touched && !!meta.error;

  const adornmentClasses = useAdornmentStyle();

  return (
    <TextField
      {...field}
      value={field.value !== null ? field.value : ''}
      {...props}
      onChange={handleChange}
      label={label}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: false,
      }}
      InputProps={{
        // Shared id (errors) was the only way to override placeholder styles
        id: 'app-text-field',
        autoComplete,
        type,
        placeholder: placeholder || label,
        startAdornment: startElement ? (
          <InputAdornment position="start" classes={adornmentClasses}>
            {startElement}
          </InputAdornment>
        ) : undefined,
        endAdornment:
          endElement || loading ? (
            <InputAdornment position="end">
              {loading && (
                <CircularProgress size="20px" thickness={5} color="primary" />
              )}
              {endElement}
            </InputAdornment>
          ) : undefined,
      }}
      error={hasError}
      helperText={hasError ? meta.error : ' '}
    />
  );
};

export default FormTextField;
