import { Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import instructionsImage from '../../../assets/images/interview-instructions-bg.png';
import { TranslationKey } from '../../../i18n/translations';
import MainButton from '../../MainButton';
import StatusBar from '../../StatusBar';
import styles from './Instructions.module.scss';

const Instructions = (): ReactElement => (
  <div className={styles.container}>
    <StatusBar
      statusText={
        <FormattedMessage id={TranslationKey.INTERVIEW_INSTRUCTIONS_STATUS} />
      }
    />

    <Grid container>
      <Grid item xs={12} md={6} className={styles.text}>
        <h2 className={styles.title}>
          <FormattedMessage id={TranslationKey.INTERVIEW_INSTRUCTIONS_TITLE} />
        </h2>

        <p>
          <FormattedMessage id={TranslationKey.INTERVIEW_INSTRUCTIONS_HEAD} />
        </p>

        <ul>
          <li>
            <FormattedMessage
              id={TranslationKey.INTERVIEW_INSTRUCTIONS_POINT_1}
            />
          </li>
          <li>
            <FormattedMessage
              id={TranslationKey.INTERVIEW_INSTRUCTIONS_POINT_2}
            />
          </li>
          <li>
            <FormattedMessage
              id={TranslationKey.INTERVIEW_INSTRUCTIONS_POINT_3}
            />
          </li>
        </ul>

        <p>
          <FormattedMessage id={TranslationKey.INTERVIEW_INSTRUCTIONS_BOTTOM} />
        </p>
      </Grid>

      <Grid item xs={12} md={6} className={styles.imageContainer}>
        <img
          src={instructionsImage}
          alt="Hand shaking characters"
          className={styles.image}
        />
      </Grid>
    </Grid>

    <div className={styles.buttonContainer}>
      <Link to="/interview/questionary">
        <MainButton type="button">
          <FormattedMessage id={TranslationKey.CONTINUE} />
        </MainButton>
      </Link>
    </div>
  </div>
);

export default Instructions;
