import React, { ReactElement, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

/**
 * Redirects interview to the correct step based on its status
 */
const InterviewStatusRedirect = (): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/interview') {
      navigate('/interview/new');
    }
  }, [location.pathname, navigate]);

  return <Outlet />;
};

export default InterviewStatusRedirect;
