import {
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Tooltip,
  TooltipProps,
} from '@material-ui/core';
import { Help as HelpIcon } from '@material-ui/icons';
import { useField } from 'formik';
import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SELECT_NONE } from '../../constants';
import { AppColor } from '../../enums';
import { SelectOption } from '../../types';

interface FormSelectFieldProps extends Partial<SelectProps> {
  name: string;
  label: string;
  options: SelectOption[];
  required?: boolean;
  loading?: boolean;
  onValueChange?: (value?: string, name?: string) => void;
  autoComplete?: 'off';
  tooltipProps?: Partial<TooltipProps>;
}

const FormSelectField: FunctionComponent<FormSelectFieldProps> = ({
  name,
  label,
  placeholder,
  options,
  required = false,
  loading = false,
  onValueChange,
  autoComplete = 'off',
  tooltipProps,
  ...props
}): ReactElement => {
  const [field, meta, { setValue }] = useField<string>(name);

  const [initValue, setInitValue] = useState(
    field.value !== null && field.value !== undefined && field.value !== ''
      ? field.value
      : SELECT_NONE,
  );

  // Syncs changes from outside component
  useEffect(() => {
    const newValue =
      field.value === SELECT_NONE ||
      field.value === null ||
      field.value === undefined ||
      field.value === ''
        ? SELECT_NONE
        : field.value;
    setInitValue(newValue);
  }, [field.value]);

  const handleChange = useCallback(
    (
      event: ChangeEvent<{ name?: string; value: unknown } | HTMLSelectElement>,
    ): void => {
      const newValue = event.target.value as string;
      setInitValue(newValue);
      setValue(newValue);
      if (onValueChange) onValueChange(newValue, name);
    },
    [name, onValueChange, setValue],
  );

  const [opened, setOpened] = useState(false);
  const handleSelectOpen = useCallback(() => {
    setOpened(true);
  }, []);
  const handleSelectClose = useCallback(() => {
    setTimeout(() => {
      setOpened(false);
    }, 500);
  }, []);

  const menuItems = useMemo(
    () =>
      options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}{' '}
        </MenuItem>
      )),
    [options],
  );

  const hasError = meta.touched && !!meta.error;

  return (
    <FormControl
      variant="outlined"
      size="small"
      required={required}
      fullWidth
      error={hasError}
    >
      <InputLabel shrink={false}>
        {label}
        {tooltipProps && (
          <Tooltip title={tooltipProps.title || ''} {...tooltipProps}>
            <IconButton
              size="small"
              style={{
                position: 'absolute',
                top: -4,
                right: -22,
                fontSize: 16,
              }}
            >
              <HelpIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
      </InputLabel>
      <Select
        {...field}
        {...props}
        value={initValue === SELECT_NONE ? initValue : field.value}
        onChange={handleChange}
        label={label}
        placeholder={placeholder || label}
        onOpen={handleSelectOpen}
        onClose={handleSelectClose}
        endAdornment={
          loading ? (
            <InputAdornment position="end">
              <CircularProgress size="20px" thickness={5} color="primary" />
            </InputAdornment>
          ) : undefined
        }
        style={{
          color: initValue === SELECT_NONE ? AppColor.Placeholder : undefined,
        }}
        autoComplete={autoComplete}
        inputProps={{
          autoComplete,
        }}
      >
        {(placeholder || label) && !opened && (
          <MenuItem value={SELECT_NONE} disabled>
            {placeholder || label}
          </MenuItem>
        )}
        {menuItems}
      </Select>
      <FormHelperText>{hasError ? meta.error : ' '}</FormHelperText>
    </FormControl>
  );
};

export default FormSelectField;
