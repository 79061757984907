import { Locale } from '../enums';
import { logger } from '../services';
import loadPolyFills from './polyfills';

loadPolyFills()
  .then(() => {
    logger.info('Loaded polyfills...');
  })
  .catch((error) => {
    logger.error('Error loading polyfills', error);
  });

const translations = {
  [Locale.Spanish]: {
    PERSONAL_EMAIL: 'Email Personal',
    PERSONAL_PHONE: 'Teléfono Personal',
    PHONE: 'Teléfono',
    QUESTION: 'Pregunta',

    TOOLTIP_OPR:
      'Selecciona si la persona es Underperformer, Newcomer, Mover o Excelling',
    TOOLTIP_EXIT_DATE:
      'Selecciona el último día del colaborador en la compañía.',
    TOOLTIP_EXIT_TYPE:
      'Indica si la persona ha salido voluntariamente (renuncia) o involuntariamente (despido/ jubilación)',
    TOOLTIP_AGREED_TO_INTERVIEW:
      'Selecciona “sí” si el colaborador quiso ser entrevistado o “no” en caso contrario.',

    HELP: 'Ayuda',
    HELP_P1:
      'Para dudas sobre el uso de la herramienta, por favor consulta <a>los tutoriales</a> que creamos para ti.',
    HELP_P2:
      'Si después de ver los tutoriales aún tienes alguna duda, ¡contáctanos! Por favor <a>envía un correo</a> con los detalles de tu pregunta.',
    HELP_P3:
      'Para dudas o soporte no resueltos a través de las opciones anteriores, dirígete a <a>SAM Portal</a> y abre un ticket. ¡Te atenderemos lo más pronto posible!',
    HELP_TITLE: '¿Cómo podemos ayudarte?',
    HOME: 'Inicio',
    PENDING: 'Pendientes',
    TOTAL_INTERVIEWS: 'Total entrevistas: {total}',

    LOCATION: 'Localidad',
    COMPLETED_INTERVIEW: 'Entrevista Completada',
    REQUEST_HELP: 'Solicitar Ayuda',
    HELP_MAIL_SUBJECT: 'Solicitud de Ayuda Exit Interview',
    HELP_MAIL_BODY: `Hola, parece que estás teniendo problemas en nuestro portal Exit Interview.
Por favor describe detalladamente cuál es el error, así nuestro equipo de soporte podrá buscar una solución lo más pronto posible.

Gracias por contactarte con nosotros, estamos para ayudarte.

Cordialmente,
Equipo Simplifica Maz`,
    PASSWORD_CHANGE_WARNING:
      'Tu contraseña está próxima a vencer. Debes cambiarla {days, plural, =1 {hoy mismo} other {en los siguientes # días}}.',
    PASSWORD_BLOCKED_ERROR:
      'Tu login ha sido bloqueado temporalmente por múltiples intentos fallidos. Podrás volver a intentarlo en {minutes, plural, =0 {menos de un minuto} =1 {un minuto} other {# minutos}}.',
    PASSWORD_VOID_ERROR:
      'Tu contraseña actual ha vencido, debes cambiarla por medio del mecanismo de olvido de contraseña para poder recuperar el acceso a la plataforma.',
    INACTIVITY_WARNING:
      'Esta sesión se cerrará automáticamente por inactividad en {minutes} minutos.',
    REPORT_UPLOAD_SUCCESS:
      'El reporte ha sido cargado correctamente y está siendo procesado.',
    REPORT_UPLOAD_ERROR:
      '¡Lo sentimos! Hubo un error cargando al cargar el reporte.',
    REPORT_LOAD_INSTRUCTIONS:
      'Arrastra y suelta el archivo del reporte o haz click aquí',
    UPLOAD_REPORT: 'Cargar reporte',
    REPORT_LOAD: 'Carga de Reporte',
    ADMINISTRATION: 'Admin',
    EXISTING_USER: 'Usuario Existente',
    ALL: 'Todas',
    GREETING: '¡Hola {firstName}!',
    INIT_QUESTION: '¿Qué quieres hacer?',
    LOADING: 'Cargando...',
    USER: 'Usuario',
    PASSWORD: 'Contraseña',
    OLD_PASSWORD: 'Contraseña antigua',
    MY_PASSWORD: 'Mi contraseña',
    SAVE_PASSWORD: 'Guardar contraseña',
    CLOSE_SESSION: 'Cerrar sesión',
    FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
    INIT_SESSION: 'Iniciar Sesión',
    RETURN: 'Regresar',
    SEND: 'Enviar',
    NEW_PASSWORD: 'Nueva Contraseña',
    REPEAT_NEW_PASSWORD: 'Repetir Nueva Contraseña',
    RECOVER_PASSWORD: 'Recuperar contraseña',
    FORGOT_PASSWORD_MESSAGE:
      'Por favor ingresa el correo asociado a tu cuenta para iniciar el proceso de recuperación de contraseña:',
    PASSWORD_RECOVERY_MESSAGE:
      'Tu contraseña ha sido actualizada exitosamente.',
    FORGOT_PASSWORD_NOTIFICATION:
      'Hemos enviado a tu correo las instrucciones para recuperar tu contraseña.',
    NAME_OR_ID: 'Nombre / ID',
    VIEW_REPORT: 'Consultar reporte',
    VIEW_TUTORIALS: 'Tutoriales',
    THANK_YOU: '¡Gracias!',
    EARLY_ENDING: 'Aquí concluye esta entrevista.',
    ACCEPT: 'Aceptar',
    CONTINUE: 'Continuar',
    BACK: 'Atrás',
    REGISTER: 'Registrar',
    APPLY_INTERVIEW: 'Aplicar entrevista',
    YES: 'Sí',
    NO: 'No',
    FINISH: 'Finalizar',
    SELECT: 'Seleccione',
    JUSTIFICATION: 'Justificación',
    ARE_YOU_SURE: '¿Estás seguro(a)?',
    SAVING: 'Guardando',
    FROM: 'Desde',
    UNTIL: 'Hasta',
    DOWNLOAD: 'Descargar',
    GENERATING_DOWNLOAD: 'Generando descarga...',
    REPORTS: 'Reportes',
    USER_MANUAL: 'Manual de usuario',
    USERS: 'Usuarios',
    ADD: 'Añadir',
    EDIT: 'Editar',
    DELETE: 'Eliminar',
    ERROR_SESSION:
      '¡Oh! No pudimos iniciar sesión con tu usuario, intenta nuevamente.',

    TERMS_AND_CONDITIONS: 'Términos y Condiciones',
    PRIVACY_POLICY: 'Política de Privacidad',
    COPYRIGHT: 'Copyright AbInBev {year}',

    NEW_INTERVIEW_STATUS: 'Registro - paso 1 / 3',
    NEW_INTERVIEW: 'Nueva entrevista',
    APPLICATION_DATE: 'Fecha de aplicación',
    EMPLOYEE_NAME: 'Nombre del colaborador',
    SEX: 'Sexo',
    LINE_MANAGER: 'Line Manager',
    LINE_MANAGER_EMAIL: 'Line Manager Email',
    BAND: 'Banda',
    POSITION: 'Posición',
    COUNTRY: 'País',
    UEN: 'UEN',
    VP: 'Vicepresidencia / Área',
    VP_SHORT: 'VP / Área',
    HIRING_DATE: 'Fecha de contratación',
    OPR_RATING: 'Calificación OPR',
    EXIT_DATE: 'Fecha de salida',
    EXIT_TYPE: 'Tipo de salida',
    AGREED_TO_INTERVIEW: '¿Accedió a la entrevista?',
    EDIT_INFO: 'Editar información',
    VOLUNTARY: 'Voluntaria',
    INVOLUNTARY: 'Involuntaria',
    BP_OBSERVATIONS: 'Observaciones de Business Partner',
    THIS_QUESTION: 'Esta pregunta',

    INTERVIEW_INSTRUCTIONS_STATUS: 'Cuestionario - paso 2 / 3',
    INTERVIEW_INSTRUCTIONS_TITLE:
      'Estás a punto de pasar a la sección de preguntas.',
    INTERVIEW_INSTRUCTIONS_HEAD:
      'Recuerda que tu trabajo es ser embajador de la cultura AB InBev hasta el último momento, por lo que es importante que consideres lo siguiente para hacer esta entrevista lo más humanamente posible:',
    INTERVIEW_INSTRUCTIONS_POINT_1:
      'Menciónale cuánto apreciamos toda la experiencia que compartió con la compañía y el tiempo que está tomándose para contestar nuestras preguntas.',
    INTERVIEW_INSTRUCTIONS_POINT_2:
      'Asegúrale que esta información es 100% confidencial. Esto significa que nadie además de ti conocerá las respuestas, por lo que no existe ninguna clase de represalias y esto de ninguna manera influirá en posibles referencias laborales que nos sean pedidas sobre él/ella.',
    INTERVIEW_INSTRUCTIONS_POINT_3:
      'Hazle saber que la entrevista tiene el propósito de encontrar áreas de oportunidad que nos ayuden a hacer de AB InBev una mejor empresa para trabajar.',
    INTERVIEW_INSTRUCTIONS_BOTTOM:
      'Una vez concluida la entrevista, es de suma importancia que agradezcas una vez más por su tiempo, sus respuestas y su honestidad; además de desearle mucha suerte en sus proyectos futuros.',

    QUESTIONARY_STATUS: 'Cuestionario - paso 2 / 3',
    QUESTIONARY_TITLE: 'Entrevista de salida',
    QUESTIONARY_EXPLANATION:
      'Selecciona la respuesta correspondiente a lo dicho por el colaborador y brinda comentarios cuando sea necesario en el espacio indicado.',
    QUESTIONARY_CONFIRMATION:
      'Una vez que finalices la entrevista, no podrás editar las respuestas de esta sección.',
    QUESTIONARY_THANKS_TITLE: '¡Muchas gracias',
    QUESTIONARY_THANKS_SUB_TITLE: 'por tu tiempo y tu colaboración!',
    ADDITIONAL_INFO_INSTRUCTIONS1:
      'Nos gustaría poder comunicarnos contigo ocasionalmente después de que dejes la compañía para preguntas / propuestas adicionales que podrían ser de tu interés. Si es posible, déjanos una dirección de correo electrónico y un número de teléfono a través del cual podamos comunicarnos contigo.',
    ADDITIONAL_INFO_INSTRUCTIONS2:
      'Esto es opcional. Esta información no se compartirá con nadie fuera de la empresa y solo estará accesible para el Departamento de People.',

    // Exit Motive
    EXIT_MOTIVE_BASE_PAY: 'Salario base',
    EXIT_MOTIVE_BENEFITS_PACKAGE: 'Paquete de beneficios',
    EXIT_MOTIVE_BONUS_INCENTIVE_REWARDS: 'Bonificaciones / incentivos',
    EXIT_MOTIVE_CAREER_ADVANCEMENT: 'Avance en su carrera',
    EXIT_MOTIVE_CURRENT_ROLE_CAREER_EXPECTATIONS:
      'El rol actual no está alineado con las expectativas profesionales',
    EXIT_MOTIVE_COMPANY_STRATEGY_CULTURE: 'Estrategia y cultura de la empresa',
    EXIT_MOTIVE_FAMILY_OBLIGATIONS: 'Obligaciones familiares',
    EXIT_MOTIVE_LEARNING_AND_DEVELOPMENT: 'Aprendizaje y desarrollo',
    EXIT_MOTIVE_LINE_MANAGER_RELATIONSHIP:
      'Relación con el (los) superior (es) directo (os)',
    EXIT_MOTIVE_OUTSIDE_CAREER_OPPORTUNITY: 'Oportunidad profesional externa',
    EXIT_MOTIVE_RELOCATING: 'Reubicación',
    EXIT_MOTIVE_RESPECT_AND_INCLUSION: 'Respeto e inclusión',
    EXIT_MOTIVE_RETURN_TO_SCHOOL: 'Volver a estudiar',
    EXIT_MOTIVE_SENIOR_LEADERSHIP: 'La alta gerencia',
    EXIT_MOTIVE_TOOLS_AND_RESOURCES: 'Herramientas y recursos',
    EXIT_MOTIVE_WORKING_ENVIRONMENT_AND_SAFETY:
      'Ambiente de trabajo y seguridad ocupacional',
    EXIT_MOTIVE_WORK_LIFE_BALANCE: 'Equilibrio vida-trabajo',
    EXIT_MOTIVE_SUM_OTHERS: 'Suma (otros motivos)',

    // Involuntary Exit Motives
    EXIT_MOTIVE_POSITIVE_ALCOHOL_TEST: 'Alcoholimetría positiva',
    EXIT_MOTIVE_POSITIVE_DRUG_TEST: 'Doping positivo',
    EXIT_MOTIVE_STEALING: 'Robo',
    EXIT_MOTIVE_FIGHT_DURING_WORK_HOURS: 'Riña en horas laborales',
    EXIT_MOTIVE_ABSENTEEISM: 'Ausentismo',
    EXIT_MOTIVE_NOT_FOLLOWING_SAFETY_PROTOCOLS:
      'Falta de procesos en rutinas de seguridad',
    EXIT_MOTIVE_WORKPLACE_HARASSMENT: 'Acoso laboral',
    EXIT_MOTIVE_SEXUAL_HARASSMENT: 'Acoso sexual',
    EXIT_MOTIVE_OPR: 'OPR',
    EXIT_MOTIVE_NOT_FOLLOWING_COVID_PROTOCOLS:
      'Incumplimiento de protocolos Covid',
    EXIT_MOTIVE_NOT_FOLLOWING_SALES_PROCEDURES:
      'Incumplimiento de gestión en punto de venta',
    EXIT_MOTIVE_SHARE_CONFIDENTIAL_INFORMATION:
      'Compartir información confidencial',
    EXIT_MOTIVE_LOW_PERFORMANCE: 'Bajo desempeño',
    EXIT_MOTIVE_BAD_BEHAVIOR: 'Mala conducta',
    EXIT_MOTIVE_FAILED_TRIAL_PERIOD: 'Periodo de prueba fallido',
    EXIT_MOTIVE_RESTRUCTURE: 'Reestructura',
    EXIT_MOTIVE_TEMPORARY_CONTRACT_TERMINATION:
      'Terminación de contrato (temporal)',
    EXIT_MOTIVE_LOSS_OF_TRUST: 'Pérdida de confianza',
    EXIT_MOTIVE_INSUBORDINATION: 'Insubordinación',
    EXIT_MOTIVE_INTERNAL_MOVEMENT: 'Movimientos entre compañías internas',
    EXIT_MOTIVE_INFORMATION_MANIPULATION:
      'Manipulación de indicadores, reportes o información',
    EXIT_MOTIVE_UPDATING: 'En actualización',

    // Other Position
    OTHER_POSITION_OPTION_INCREASED_SALARY: 'Mayor salario',
    OTHER_POSITION_OPTION_INCREASED_RESPONSIBILITIES:
      'Mayores responsabilidades',
    OTHER_POSITION_OPTION_INCREASED_ADVANCEMENT_OPPORTUNITIES:
      'Mayor oportunidad de crecimiento laboral',
    OTHER_POSITION_OPTION_INCREASED_PRESTIGE: 'Mayor prestigio de la compañía',
    OTHER_POSITION_OPTION_INCREASED_FORMAL_BENEFITS:
      'Mejor paquete de compensaciones (beneficios, vacaciones, cobertura de salud, etc.)',
    OTHER_POSITION_OPTION_INCREASED_LIFESTYLE_BENEFITS:
      'Mayores beneficios en equilibrio vida-trabajo (flexibilidad en horas de trabajo, home-office, etc.)',
    OTHER_POSITION_OPTION_VALUES_ALIGNMENT:
      'La industria o tipo de trabajo me interesa más o es más compatible a mis valores o cultura',
    OTHER_POSITION_OPTION_BETTER_LOCATION: 'Mejor ubicación',
    OTHER_POSITION_OPTION_LESS_TRAVEL_REQUIRED: 'No tengo que viajar tanto',
    OTHER_POSITION_OPTION_MORE_TRAVEL_OPPORTUNITIES:
      'Tengo más oportunidades de viajar',

    // Recommends Options
    RECOMMENDS_OPTION_YES_DEFINITELY: 'Sí, definitivamente',
    RECOMMENDS_OPTION_YES_MAYBE: 'Sí, tal vez',
    RECOMMENDS_OPTION_PROBABLY_NOT: 'Probablemente no',
    RECOMMENDS_OPTION_DEFINITELY_NOT: 'Definitivamente no',

    // Common Options
    STRONGLY_AGREE: 'Totalmente de acuerdo',
    AGREE: 'De acuerdo',
    NEITHER_AGREE_OR_DISAGREE: 'Ni de acuerdo ni en desacuerdo',
    DISAGREE: 'En desacuerdo',
    STRONGLY_DISAGREE: 'Totalmente en desacuerdo',

    PRE_FEEDBACK_STATUS: 'Retroalimentación a LM - paso 3 / 3',
    PRE_FEEDBACK_CONTINUE: 'Continuar a retroalimentación',
    FEEDBACK: 'Retroalimentación',
    EXIT: 'Salir',
    PRE_FEEDBACK_TEXT_1:
      'Ahora es momento de brindar una sesión de retroalimentación al Line Manager de nuestro ex colaborador.',
    PRE_FEEDBACK_TEXT_2:
      'En el siguiente apartado encontrarás un espacio para registrar lo acontecido durante esta sesión. Puedes completarla ahora o  hacerlo más tarde, accediendo a ella desde el apartado  “Entrevistas pendientes” del Menú.',
    PRE_FEEDBACK_QUESTION: '¿Qué deseas hacer?',

    FEEDBACK_STATUS: 'Retroalimentación a LM - paso 3 / 3',
    FEEDBACK_TITLE: 'Retroalimentación a Line Manager',
    FEEDBACK_EXPLANATION:
      'Completa los campos que se presentan a continuación. Recuerda que <b>toda la información que llenes en esta sección será compartida directamente con tu Line Manager</b>.',
    FEEDBACK_ACTION_PRE_EXPLANATION: 'Descripción general del Plan de Acción',
    FEEDBACK_ACTION_POST_EXPLANATION:
      'Recuerda que este plan deberá ser ejecutado en el Plan de Acción de Engagement actual',
    FEEDBACK_BUTTON: 'Enviar a LM',
    FEEDBACK_CONFIRMATION:
      'Una vez que envíes a LM, la información no podrá modificarse a menos que él/ella así lo solicite',

    READY: '¡Listo!',
    FEEDBACK_SENT_PARAGRAPH_1:
      'Hemos enviado un correo al Line Manager donde podrá corroborar esta información.',
    FEEDBACK_SENT_PARAGRAPH_2:
      'Una vez nos dé su confirmación, la entrevista habrá concluido satisfactoriamente. Te notificaremos cuando esto suceda.',

    NOTICE_CONFIRMED_PARAGRAPH_1:
      'La entrevista de salida con ID <strong>{interviewId}</strong> ha concluido satisfactoriamente.',
    NOTICE_CONFIRMED_PARAGRAPH_2:
      'Gracias por ayudarnos a hacer de ABI un mejor lugar para trabajar.',

    NOTICE_DECLINED_PARAGRAPH_1:
      'Hemos avisado a tu Business Partner que no estás de acuerdo con lo establecido en tu retroalimentación para que revise tu situación.',
    NOTICE_DECLINED_PARAGRAPH_2:
      'Te pedimos intentes contactarlo tan pronto como sea posible.',
    NOTICE_DECLINED_THANKS: '¡Gracias por tu cooperación!',

    LM_FEEDBACK_SENT_SUCCESS:
      'Tu respuesta ha sido enviada correctamente. ¡Gracias por tu ayuda!',
    LM_FEEDBACK_ALREADY_SENT_ERROR:
      'Ya has decidido sobre la sesión de feedback.',
    LM_FEEDBACK_SENT_ERROR:
      '¡Lo sentimos! Ha ocurrido un error al intentar enviar tu respuesta.',

    /**
     * History
     */
    HISTORY: 'Historial',
    INTERVIEW_COUNT:
      '{interviewCount, plural, =0 {No has realizado ninguna entrevista} =1 {Has realizado 1 entrevista} other {Has realizado # entrevistas}} hasta el momento.',
    HISTORY_INSTRUCTIONS:
      'Para consultarlas filtra por rango de fechas o busca por nombre o ID del colaborador.',

    HISTORY_COLUMN_SHARP: 'ID',
    HISTORY_COLUMN_EMPLOYEE: 'Empleado',
    HISTORY_COLUMN_LINE_MANAGER: 'Line Manager',
    HISTORY_COLUMN_CREATED_AT: 'Fecha de creación',
    HISTORY_COLUMN_INTERVIEWER: 'Entrevistador',
    HISTORY_COLUMN_COUNTRY: 'País',
    HISTORY_COLUMN_VICE_PRESIDENCY: 'VP / Área',
    HISTORY_COLUMN_UEN: 'UEN',
    HISTORY_COLUMN_STATUS: 'Estado',

    HISTORY_ROW_DISPLAY: '{from}-{to} de {count}',
    PENDING_INTERVIEWS: 'Entrevistas pendientes',
    NO_PENDING_INTERVIEWS: 'Tienes 0 entrevistas pendientes.',

    INTERVIEW_STATUS_REGISTERED: 'Registrada',
    INTERVIEW_STATUS_INTERVIEW: 'Entrevista',
    INTERVIEW_STATUS_FEEDBACK: 'Retroalimentación a LM',
    INTERVIEW_STATUS_FEEDBACK_SENT: 'Retroalimentación enviada',
    INTERVIEW_STATUS_FEEDBACK_REJECTED: 'Rechazado - LM',
    INTERVIEW_STATUS_FEEDBACK_ACCEPTED: 'Completado - LM',
    INTERVIEW_STATUS_FEEDBACK_NOT_CONFIRMED: 'Completado',

    /**
     * Reports
     */
    REPORTS_INSTRUCTIONS:
      'Selecciona la información que necesites para generar una gráfica',
    PARAMETER: 'Parámetro',
    PERIOD: 'Periodo',
    SHOW_BY: 'Mostrar por',
    OPTIONAL: 'Opcional',
    GENERATE_CHART: 'Generar gráfica',
    GENERATING: 'Generando...',
    REPORT_TYPE_KPI: 'Cumplimiento KPI',
    REPORT_TYPE_SLA: 'Motivos de salida',
    START_DATE: 'Fecha Inicial',
    END_DATE: 'Fecha Final',
    ZONE: 'Zona',
    ANOTHER_REPORT: '¿Necesitas otro reporte?',
    ANOTHER_REPORT_SUB: 'Descarga el archivo de Excel que necesites.',
    DOWNLOAD_REPORT: 'Descargar reporte',
    DOWNLOAD_IMAGE: 'Descargar imagen',
    COMPLETED: 'Completadas',
    UNANSWERED: 'Registradas',
    IN_PROGRESS: 'En progreso',
    MISSED: 'Faltantes',
    REPORT_TYPE_KPI_TITLE: 'KPI',
    COMPLIANCE_PERCENTAGE: '% de cumplimiento',
    REPORT_TYPE_SLA_TITLE: 'Motivos de salida',
    UNREGISTERED_INTERVIEWS: 'Entrevistas no registradas',
    UNREGISTERED_TOOLTIP_ADMINS:
      'Descarga la lista de colaboradores que aún tienen pendiente registrar su entrevista de salida.',
    UNREGISTERED_TOOLTIP_REGULAR:
      'Descarga la lista de colaboradores de tu UEN que aún tienen pendiente de registrar su entrevista de salida.',
    COLLABORATOR_EXIT_DATE: 'Fecha de salida del colaborador',
    WORD_CLOUD: 'Nube de Palabras',
    WORD_CLOUD_INTERVIEW_COUNT: 'Número de entrevistas: {count}',

    /**
     * Admin
     */
    SEARCH: 'Buscar',
    ADMIN_ADD: 'añadir',
    ADMIN_EDIT: 'editar',
    ADMIN_DELETE: 'eliminar',
    ADMIN_SEARCH_INSTRUCTIONS:
      'Escribe en el siguiente campo el ID o el Nombre del usuario que deseas {action} y presiona enter:',
    ADMIN_COLUMN_ID: 'ID',
    ADMIN_COLUMN_INTERVIEWER: 'Entrevistador(a)',
    ADMIN_COLUMN_EMAIL: 'Email',
    ADMIN_COLUMN_UEN: 'UEN',
    ADMIN_COLUMN_ROLE: 'Role',
    SUBMITTING_ADD: 'Añadiendo...',
    SUBMITTING_EDIT: 'Modificando...',
    SUBMITTING_DELETE: 'Eliminando...',
    USERS_UPDATE_SUCCESS:
      '¡Listo! Los usuarios seleccionados han sido actualizados correctamente.',
    USERS_UPDATE_ERROR:
      '¡Lo sentimos! Hubo un error al intentar actualizar los usuarios seleccionados.',
    ADMIN_INVALID_EMAIL:
      'Algún correo de los usuarios que estás intentando {action} no es valido. Por favor verifica e intenta de nuevo.',

    USER_ROLE_ADMINISTRATOR: 'Administrador',
    USER_ROLE_OWNER: 'BU Owner',
    USER_ROLE_COUNTRY_OWNER: 'Country Owner',
    USER_ROLE_REGULAR: 'Regular',
    USER_ROLE_INVALID: 'Inactivo',
    WITHDRAWALS: 'Bajas',

    /**
     * Autocomplete
     */
    AUTOCOMPLETE_NO_OPTIONS: 'No hay opciones para la búsqueda.',
    AUTOCOMPLETE_INSTRUCTIONS: 'Empieza a escribir para buscar',

    /**
     * Validation
     */
    VALIDATE_EMAIL: 'El email que has ingresado no es valido.',
    VALIDATE_REQUIRED_GENERIC: 'Este campo es requerido.',
    VALIDATE_REQUIRED: '{fieldName} es un campo requerido.',
    VALIDATE_PASSWORD_NOT_NEW:
      'La nueva contraseña debe ser diferente de la contraseña actual.',
    VALIDATE_PASSWORD_MATCH: 'Las contraseñas deben coincidir.',
    VALIDATE_PASSWORD_INSTRUCTIONS:
      'Debe tener al menos {length} caracteres, un número, una minúscula, una mayúscula y un símbolo (. @ $ ! % * # ? & -)',
    VALIDATE_CREDENTIALS_ERROR: 'Contraseña o usuario incorrectos.',
    VALIDATE_CURRENT_PASSWORD: 'La contraseña actual es incorrecta.',
    VALIDATE_MUST_SELECT_SCOPE: 'Debes seleccionar un scope.',

    /**
     * Messages (toast)
     */
    ERROR_FETCHING_COLLABORATOR:
      '¡Lo sentimos! Hubo un error descargando la información del colaborador.',
    ERROR_INTERVIEW_EXISTS:
      'La entrevista para el colaborador con Sharp ID {sharpId}, ya existe en el sistema.',
    ERROR_INTERVIEW_OUT_OF_SCOPE:
      'No puedes crear una entrevista para un colaborador que está fuera de tu alcance (Diferente país, BU, etc...).',
    ERROR_CREATING_INTERVIEW:
      '¡Lo sentimos! Hubo un error creando la entrevista.',
    ERROR_FETCHING_STORED_QUESTIONARY:
      '¡Lo sentimos! Hubo un error descargando la información guardada sobre la entrevista.',
    ERROR_SAVING_PARTIAL_QUESTIONARY:
      '¡Lo sentimos! Hubo un error guardando el parcial de las preguntas.',
    ERROR_SAVING_QUESTIONS:
      '¡Lo sentimos! Hubo un error guardando las preguntas.',
    ERROR_FETCHING_STORED_FEEDBACK:
      '¡Lo sentimos! Hubo un error descargando la información guardada sobre el feedback.',
    ERROR_SAVING_PARTIAL_FEEDBACK:
      '¡Lo sentimos! Hubo un error guardando el parcial del feedback.',
    ERROR_SAVING_FEEDBACK: '¡Lo sentimos! Hubo un error guardando el feedback.',
    ERROR_DOWNLOADING_REPORT:
      '¡Lo sentimos! Hubo un error descargando el reporte.',
    ERROR_DROPZONE_FILE_TOO_LARGE:
      'El archivo que estás intentando cargar excede {fileSize}, por favor verifica el archivo o contacta a soporte.',

    SUCCESS_CREATING_INTERVIEW: 'La entrevista ha sido creada correctamente.',
    SUCCESS_SAVING_QUESTIONS: 'Las preguntas han sido guardadas correctamente',
    SUCCESS_SAVING_FEEDBACK: 'El feedback ha sido guardado correctamente.',

    BUSINESS_UNIT_CAC: 'CAC',
    BUSINESS_UNIT_CO: 'Colombia',
    BUSINESS_UNIT_EC: 'Ecuador',
    'BUSINESS_UNIT_HN / SV': 'Honduras / Salvador',
    BUSINESS_UNIT_MEX: 'Mexico',
    BUSINESS_UNIT_PE: 'Perú',

    VP_SALES: 'Ventas',
    VP_FINANCE: 'Finanzas',
    'VP_LEGAL / CORPORATE AFFAIRS': 'Legal',
    VP_LOGISTICS: 'Logística',
    VP_SUPPLY: 'Supply',
    VP_SOLUTIONS: 'Solutions',
    VP_MARKETING: 'Marketing',
    VP_PROCUREMENT: 'Procurement',
    VP_PEOPLE: 'People',

    COMMENTS: 'Comentarios',
    BOARD: 'Dashboard',
    ADMIN_COLUMN_DH_ACCESS: 'Acceso Dashboard',
    MESSAGE_CRITICAL: '<p> Sí dureante la entrevista identificaste una situación de <strong>DISCRIMINACIÓN, ACOSO</strong> o <strong>VIOLENCIA</strong> de cualquier tipo , hacía el/la colaborador(a) durante su vida laborar en ABI, es importante que las señales a continuación como <strong>CRÍTICA.</strong> </p> <p> En caso de que no se refiera a estas situaciones, <strong>evita</strong> marcarla como <strong>CRÍTICA</strong> ya que la información se envía a <strong>People Heads</strong> para analizar detalladamente la situación, y estos se pondrán en contacto contigo en caso de requerir información. </p>'
  },

  [Locale.English]: {
    PERSONAL_EMAIL: 'Personal Email',
    PERSONAL_PHONE: 'Personal Phone',
    PHONE: 'Phone',
    QUESTION: 'Question',

    TOOLTIP_OPR:
      'Select if the person is Underperformer, Newcomer, Mover o Excelling.',
    TOOLTIP_EXIT_DATE: "Select the collaborator's last day in the company.",
    TOOLTIP_EXIT_TYPE:
      'Indicate if the person has left voluntarily (resignation) or involuntarily (dismissal/retirement).',
    TOOLTIP_AGREED_TO_INTERVIEW:
      'Select “yes” if the collaborator wanted to be interviewed or “no” otherwise.',

    HELP: 'Help',
    HELP_TITLE: 'How can we help you?',
    HELP_P1:
      'For questions about the use of the tool, please consult <a>the tutorials</a> that we created for you.',
    HELP_P2:
      'After watching the tutorials, if you still have any questions, please contact us! <a>Send an email</a> with the details of your question.',
    HELP_P3:
      'For unresolved questions or support that were not resolved with the above options, go to <a>SAM Portal</a> and open a ticket. We will get back to you as soon as possible!',

    HOME: 'Home',
    PENDING: 'Pending',
    TOTAL_INTERVIEWS: 'Total interviews: {total}',

    LOCATION: 'Location',
    COMPLETED_INTERVIEW: 'Completed Interview',
    REQUEST_HELP: 'Request Help',
    HELP_MAIL_SUBJECT: 'Help Exit Interview',
    HELP_MAIL_BODY: `Hello, it seems that you are having problems on our portal Exit Interview.

Please describe in detail what the error is, so our support team can find a solution as soon as possible.

Thank you for contacting us, we are here to help you.

Cordially,
Simplify Maz Team`,

    PASSWORD_CHANGE_WARNING:
      'Your password is close to expire. You must change it {days, plural, =1 {today} other {in the following # days}}.',
    PASSWORD_BLOCKED_ERROR:
      'Your login has been temporarily blocked for multiple failed attempts. You may retry it in {minutes, plural, =0 {less than a minute} =1 {a minute} other {# minutes}}.',
    PASSWORD_VOID_ERROR:
      'Your current password has expired, you must use the forgot password mechanism to regain access to the platform.',
    INACTIVITY_WARNING:
      'This session will be close automatically for inactivity in {minutes} minutes.',
    REPORT_UPLOAD_SUCCESS:
      'The report has been uploaded successfully and it is being processed.',
    REPORT_UPLOAD_ERROR:
      'We are sorry! There was an error while uploading the report.',
    REPORT_LOAD_INSTRUCTIONS: 'Drag and drop the report file or click here',
    UPLOAD_REPORT: 'Upload Report',
    REPORT_LOAD: 'Report Load',
    ADMINISTRATION: 'Admin',
    EXISTING_USER: 'Existing User',
    ALL: 'All',
    GREETING: 'Hello {firstName}!',
    INIT_QUESTION: 'What do you want to do?',
    LOADING: 'Loading...',
    USER: 'User',
    PASSWORD: 'Password',
    OLD_PASSWORD: 'Old password',
    MY_PASSWORD: 'My password',
    SAVE_PASSWORD: 'Save password',
    CLOSE_SESSION: 'Close session',
    FORGOT_PASSWORD: 'Forgot password?',
    INIT_SESSION: 'Start Session',
    RETURN: 'Go back',
    SEND: 'Send',
    NEW_PASSWORD: 'New Password',
    REPEAT_NEW_PASSWORD: 'Repeat New Password',
    RECOVER_PASSWORD: 'Recover Password',
    FORGOT_PASSWORD_MESSAGE:
      'Please enter the email associated with your account to start the password recovery process:',
    PASSWORD_RECOVERY_MESSAGE: 'Your password has been successfully changed.',
    FORGOT_PASSWORD_NOTIFICATION:
      'We have sent to your email the instructions to recover your password.',
    CONTINUE: 'Continue',
    BACK: 'Back',
    REGISTER: 'Register',
    APPLY_INTERVIEW: 'Apply Interview',
    YES: 'Yes',
    NO: 'No',
    FINISH: 'Finish',
    SELECT: 'Select',
    JUSTIFICATION: 'Justification',
    ARE_YOU_SURE: 'Are you sure?',
    SAVING: 'Saving',
    FROM: 'From',
    UNTIL: 'Until',
    NAME_OR_ID: 'Name / ID',
    VIEW_REPORT: 'View Report',
    VIEW_TUTORIALS: 'Tutorials',
    THANK_YOU: 'Thank You!',
    EARLY_ENDING: 'This interview concludes here.',
    ACCEPT: 'Accept',
    DOWNLOAD: 'Download',
    GENERATING_DOWNLOAD: 'Generating download...',
    REPORTS: 'Reports',
    USER_MANUAL: 'User Manual',
    USERS: 'Users',
    ADD: 'Add',
    EDIT: 'Edit',
    DELETE: 'Delete',
    ERROR_SESSION:
      'Oh! We could not log in with your username, please try again.',

    TERMS_AND_CONDITIONS: 'Terms and Conditions',
    PRIVACY_POLICY: 'Privacy Policy',
    COPYRIGHT: 'Copyright AbInBev {year}',

    NEW_INTERVIEW_STATUS: 'Register - step 1 / 3',
    NEW_INTERVIEW: 'New Interview',
    APPLICATION_DATE: 'Application Date',
    EMPLOYEE_NAME: 'Associate Name',
    SEX: 'Sex',
    LINE_MANAGER: 'Line Manager',
    LINE_MANAGER_EMAIL: 'Line Manager Email',
    BAND: 'Band',
    POSITION: 'Position',
    COUNTRY: 'Country',
    UEN: 'UEN',
    VP: 'Vice-Presidency / Area',
    VP_SHORT: 'VP / Area',
    HIRING_DATE: 'Hiring Date',
    OPR_RATING: 'OPR Rating',
    EXIT_DATE: 'Exit Date',
    EXIT_TYPE: 'Exit Type',
    AGREED_TO_INTERVIEW: 'Agreed to be interviewed?',
    EDIT_INFO: 'Edit information',
    VOLUNTARY: 'Voluntary',
    INVOLUNTARY: 'Involuntary',
    BP_OBSERVATIONS: 'Business Partner observations',
    THIS_QUESTION: 'This question',

    INTERVIEW_INSTRUCTIONS_STATUS: 'Questionary - step 2 / 3',
    INTERVIEW_INSTRUCTIONS_TITLE:
      'You are about to go into the questions section.',
    INTERVIEW_INSTRUCTIONS_HEAD:
      'Remember that your job is to be an ambassador of AB InBev culture until the last moment, so it is important that you consider the following to perform this interview as humanly as possible:',
    INTERVIEW_INSTRUCTIONS_POINT_1:
      'Mention how much we appreciate all the experience shared with the company and the time he/she is taking to answer our questions.',
    INTERVIEW_INSTRUCTIONS_POINT_2:
      'Assure him/her that this information is 100% confidential. This means that no one besides you will know the answers, so there is no retaliation of any kind and this in no way will influence possible job references that are asked of him/her.',
    INTERVIEW_INSTRUCTIONS_POINT_3:
      'Let him/her know that the interview is intended to find areas of opportunity that will help us make ABInBev a better company to work for.',
    INTERVIEW_INSTRUCTIONS_BOTTOM:
      'Once the interview is over, it is of utmost importance that you once again thank them for their time, their responses and their honesty; in addition to wishing him/her good luck in his future projects.',

    QUESTIONARY_STATUS: 'Questionary - step 2 / 3',
    QUESTIONARY_TITLE: 'Exit Interview',
    QUESTIONARY_EXPLANATION:
      'Select the answer corresponding to what the collaborator said and provide comments when necessary in the space provided.',
    QUESTIONARY_CONFIRMATION:
      'Once you finish the interview, you will not be able to edit the answers in this section.',
    QUESTIONARY_THANKS_TITLE: 'Thank you very much',
    QUESTIONARY_THANKS_SUB_TITLE: 'for your time and collaboration!',
    ADDITIONAL_INFO_INSTRUCTIONS1:
      'We would like to be able to occasionally reach out to  you after you leave the company for additional questions / proposals that might be of interest to you. If possible, please leave an email address and phone number through which we could contact you.',
    ADDITIONAL_INFO_INSTRUCTIONS2:
      'This is optional. This information will not be shared with anyone outside of the company and will only be accessible to People Department. ',

    // Exit Motive Options
    EXIT_MOTIVE_BASE_PAY: 'Base Pay',
    EXIT_MOTIVE_BENEFITS_PACKAGE: 'Benefits package',
    EXIT_MOTIVE_BONUS_INCENTIVE_REWARDS: 'Bonus/incentive rewards',
    EXIT_MOTIVE_CAREER_ADVANCEMENT: 'Career advancement',
    EXIT_MOTIVE_CURRENT_ROLE_CAREER_EXPECTATIONS:
      'Current role not inline with career expectations',
    EXIT_MOTIVE_COMPANY_STRATEGY_CULTURE: 'Company strategy and culture',
    EXIT_MOTIVE_FAMILY_OBLIGATIONS: 'Family obligations',
    EXIT_MOTIVE_LEARNING_AND_DEVELOPMENT: 'Learning and development',
    EXIT_MOTIVE_LINE_MANAGER_RELATIONSHIP: 'Relationship with line manager(s)',
    EXIT_MOTIVE_OUTSIDE_CAREER_OPPORTUNITY: 'Outside career opportunity',
    EXIT_MOTIVE_RELOCATING: 'Relocating',
    EXIT_MOTIVE_RESPECT_AND_INCLUSION: 'Respect and Inclusion',
    EXIT_MOTIVE_RETURN_TO_SCHOOL: 'Return to school',
    EXIT_MOTIVE_SENIOR_LEADERSHIP: 'Senior leadership',
    EXIT_MOTIVE_TOOLS_AND_RESOURCES: 'Tools and resources',
    EXIT_MOTIVE_WORKING_ENVIRONMENT_AND_SAFETY: 'Working environment & safety',
    EXIT_MOTIVE_WORK_LIFE_BALANCE: 'Work-life balance',
    EXIT_MOTIVE_SUM_OTHERS: 'Sum (other motives)',

    // Involuntary Exit Motives
    EXIT_MOTIVE_POSITIVE_ALCOHOL_TEST: 'Positive alcohol test',
    EXIT_MOTIVE_POSITIVE_DRUG_TEST: 'Positive drug test',
    EXIT_MOTIVE_STEALING: 'Stealing',
    EXIT_MOTIVE_FIGHT_DURING_WORK_HOURS: 'Fight during work hours',
    EXIT_MOTIVE_ABSENTEEISM: 'Absenteeism',
    EXIT_MOTIVE_NOT_FOLLOWING_SAFETY_PROTOCOLS:
      'Not following safety protocols',
    EXIT_MOTIVE_WORKPLACE_HARASSMENT: 'Workplace harassment',
    EXIT_MOTIVE_SEXUAL_HARASSMENT: 'Sexual harassment',
    EXIT_MOTIVE_OPR: 'OPR',
    EXIT_MOTIVE_NOT_FOLLOWING_COVID_PROTOCOLS: 'Not following Covid protocols',
    EXIT_MOTIVE_NOT_FOLLOWING_SALES_PROCEDURES:
      'Not following sales procedures',
    EXIT_MOTIVE_SHARE_CONFIDENTIAL_INFORMATION:
      'Share confidential information',
    EXIT_MOTIVE_LOW_PERFORMANCE: 'Low performance',
    EXIT_MOTIVE_BAD_BEHAVIOR: 'Bad behavior',
    EXIT_MOTIVE_FAILED_TRIAL_PERIOD: 'Failed trial period',
    EXIT_MOTIVE_RESTRUCTURE: 'Restructure',
    EXIT_MOTIVE_TEMPORARY_CONTRACT_TERMINATION:
      'Contract Termination (temporary)',
    EXIT_MOTIVE_LOSS_OF_TRUST: 'Trust loss',
    EXIT_MOTIVE_INSUBORDINATION: 'Insubordination',
    EXIT_MOTIVE_INTERNAL_MOVEMENT: 'Movement between internal companies',
    EXIT_MOTIVE_INFORMATION_MANIPULATION:
      'Manipulation of indicators, reports or information',
    EXIT_MOTIVE_UPDATING: 'Updating',

    // Other Position
    OTHER_POSITION_OPTION_INCREASED_SALARY: 'Increased salary',
    OTHER_POSITION_OPTION_INCREASED_RESPONSIBILITIES:
      'Increased responsibilities',
    OTHER_POSITION_OPTION_INCREASED_ADVANCEMENT_OPPORTUNITIES:
      'Increased advancement opportunities',
    OTHER_POSITION_OPTION_INCREASED_PRESTIGE: 'Increased prestige',
    OTHER_POSITION_OPTION_INCREASED_FORMAL_BENEFITS:
      'Improved formal benefits, like healthcare, leave, or vacation time',
    OTHER_POSITION_OPTION_INCREASED_LIFESTYLE_BENEFITS:
      'Improved lifestyle benefits, like flexible work hours, working from home, or job sharing',
    OTHER_POSITION_OPTION_VALUES_ALIGNMENT:
      'The industry or actual type of work was more interesting to me or aligned with my values and/or cultural preferences',
    OTHER_POSITION_OPTION_BETTER_LOCATION: 'Better location',
    OTHER_POSITION_OPTION_LESS_TRAVEL_REQUIRED: 'Less travel required',
    OTHER_POSITION_OPTION_MORE_TRAVEL_OPPORTUNITIES:
      'More opportunities for travel',

    //  Recommends Options
    RECOMMENDS_OPTION_YES_DEFINITELY: 'Yes, definitely',
    RECOMMENDS_OPTION_YES_MAYBE: 'Yes, maybe',
    RECOMMENDS_OPTION_PROBABLY_NOT: 'Probably not',
    RECOMMENDS_OPTION_DEFINITELY_NOT: 'Definitely not',

    // Common Options
    STRONGLY_AGREE: 'Strongly Agree',
    AGREE: 'Agree',
    NEITHER_AGREE_OR_DISAGREE: 'Neither Agree or Disagree',
    DISAGREE: 'Disagree',
    STRONGLY_DISAGREE: 'Strongly Disagree',

    PRE_FEEDBACK_STATUS: 'LM Feedback - step 3 / 3',
    PRE_FEEDBACK_CONTINUE: 'Continue with feedback',
    FEEDBACK: 'Feedback',
    EXIT: 'Exit',
    PRE_FEEDBACK_TEXT_1:
      'Now is the time to provide a feedback session to the Line Manager of our former collaborator.',
    PRE_FEEDBACK_TEXT_2:
      'In the next section you will find a space to record what happened during this session. You can complete it now or do it later, by accessing it from the "Pending interviews" section of the Menu.',
    PRE_FEEDBACK_QUESTION: 'What do you want to do?',

    FEEDBACK_STATUS: 'Feedback to LM - step 3 / 3',
    FEEDBACK_TITLE: 'Feedback to Line Manager',
    FEEDBACK_EXPLANATION:
      'Please complete the fields below. Remember that <b>all the information you fill out in this section will be shared directly with your Line Manager</b>.',
    FEEDBACK_ACTION_PRE_EXPLANATION: 'General description of the Action Plan',
    FEEDBACK_ACTION_POST_EXPLANATION:
      'Remember that this plan must be executed in the current Engagement Action Plan',
    FEEDBACK_BUTTON: 'Send to LM',
    FEEDBACK_CONFIRMATION:
      'Once you submit to LM, the information cannot be changed unless he/she requests it.',

    READY: 'Ready!',
    FEEDBACK_SENT_PARAGRAPH_1:
      'We have sent an email to the Line Manager where he/she can corroborate this information.',
    FEEDBACK_SENT_PARAGRAPH_2:
      'Once he/she gives us his/her confirmation, the interview will have concluded successfully. We will notify you when this happens. ',

    NOTICE_CONFIRMED_PARAGRAPH_1:
      'La entrevista de salida con ID <strong>{interviewId}</strong> ha concluido satisfactoriamente.',
    NOTICE_CONFIRMED_PARAGRAPH_2:
      'Gracias por ayudarnos a hacer de ABI un mejor lugar para trabajar.',

    NOTICE_DECLINED_PARAGRAPH_1:
      'We have notified your Business Partner that you do not agree with the provisions of your feedback to review your situation.',
    NOTICE_DECLINED_PARAGRAPH_2:
      'We ask that you try to contact him as soon as possible.',
    NOTICE_DECLINED_THANKS: 'Thanks for your cooperation!',

    LM_FEEDBACK_SENT_SUCCESS:
      'Your answer has been sent correctly. Thanks for your help!',
    LM_FEEDBACK_ALREADY_SENT_ERROR:
      'You have already decided on the feedback session.',
    LM_FEEDBACK_SENT_ERROR:
      'We are sorry! An error occurred while trying to send your answer.',

    /**
     * History
     */
    HISTORY: 'History',
    INTERVIEW_COUNT:
      '{interviewCount, plural, =0 {You have not conducted any interview} =1 {You have conducted 1 interview} other {You have conducted # interviews}} until now.',
    HISTORY_INSTRUCTIONS:
      'To consult them, filter by date range or search by collaborator name or ID.',

    HISTORY_COLUMN_SHARP: 'ID',
    HISTORY_COLUMN_EMPLOYEE: 'Employee',
    HISTORY_COLUMN_LINE_MANAGER: 'Line Manager',
    HISTORY_COLUMN_CREATED_AT: 'Creation Date',
    HISTORY_COLUMN_INTERVIEWER: 'Interviewer',
    HISTORY_COLUMN_COUNTRY: 'Country',
    HISTORY_COLUMN_VICE_PRESIDENCY: 'VP / Area',
    HISTORY_COLUMN_UEN: 'UEN',
    HISTORY_COLUMN_STATUS: 'Status',

    HISTORY_ROW_DISPLAY: '{from}-{to} to {count}',

    PENDING_INTERVIEWS: 'Pending Interviews',
    NO_PENDING_INTERVIEWS: 'You have 0 pending interviews.',

    INTERVIEW_STATUS_REGISTERED: 'Registered',
    INTERVIEW_STATUS_INTERVIEW: 'Interview',
    INTERVIEW_STATUS_FEEDBACK: 'Feedback to LM',
    INTERVIEW_STATUS_FEEDBACK_SENT: 'Feedback Sent',
    INTERVIEW_STATUS_FEEDBACK_REJECTED: 'Rejected - LM',
    INTERVIEW_STATUS_FEEDBACK_ACCEPTED: 'Completed - LM',
    INTERVIEW_STATUS_FEEDBACK_NOT_CONFIRMED: 'Completed',

    /**
     * Reports
     */
    REPORTS_INSTRUCTIONS: 'Select the information you need to generate a graph',
    PARAMETER: 'Parameter',
    PERIOD: 'Period',
    SHOW_BY: 'Show by',
    OPTIONAL: 'Optional',
    GENERATE_CHART: 'Generate chart',
    GENERATING: 'Generating...',
    REPORT_TYPE_KPI: 'KPI Compliance',
    REPORT_TYPE_SLA: 'Exit Motives',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    ZONE: 'Zone',
    ANOTHER_REPORT: 'Do you need another report?',
    ANOTHER_REPORT_SUB: 'Download the Excel file that you need.',
    DOWNLOAD_REPORT: 'Download Report',
    DOWNLOAD_IMAGE: 'Download imagen',
    COMPLETED: 'Completed',
    UNANSWERED: 'Registered',
    IN_PROGRESS: 'In progress',
    MISSED: 'Missed',
    REPORT_TYPE_KPI_TITLE: 'KPI',
    COMPLIANCE_PERCENTAGE: 'Compliance %',
    REPORT_TYPE_SLA_TITLE: 'Exit Motives',
    UNREGISTERED_INTERVIEWS: 'Unregistered Interviews',
    UNREGISTERED_TOOLTIP_ADMINS:
      'Download the list of collaborators who have yet to register their exit interview.',
    UNREGISTERED_TOOLTIP_REGULAR:
      'Download the list of your UEN collaborators who have yet to register their exit interview.',
    COLLABORATOR_EXIT_DATE: "Collaborator's exit date",
    WORD_CLOUD: 'Word Cloud',
    WORD_CLOUD_INTERVIEW_COUNT: 'Interview count: {count}',

    /**
     * Admin
     */
    SEARCH: 'Search',
    ADMIN_ADD: 'add',
    ADMIN_EDIT: 'edit',
    ADMIN_DELETE: 'delete',
    ADMIN_SEARCH_INSTRUCTIONS:
      'Write in the next field the ID or Name of the user that you whish to {action} and press Enter:',
    ADMIN_COLUMN_ID: 'ID',
    ADMIN_COLUMN_INTERVIEWER: 'Interviewer',
    ADMIN_COLUMN_EMAIL: 'Email',
    ADMIN_COLUMN_UEN: 'UEN',
    ADMIN_COLUMN_ROLE: 'Role',
    SUBMITTING_ADD: 'Adding...',
    SUBMITTING_EDIT: 'Modifying...',
    SUBMITTING_DELETE: 'Deleting...',
    USERS_UPDATE_SUCCESS: 'The selected users have been updated correctly.',
    USERS_UPDATE_ERROR:
      'We are sorry! There was an error while updating the selected users.',
    ADMIN_INVALID_EMAIL:
      'Some email from the users that you are trying to {action} is not valid. Please verify it and try again.',

    USER_ROLE_ADMINISTRATOR: 'Administrator',
    USER_ROLE_OWNER: 'BU Owner',
    USER_ROLE_COUNTRY_OWNER: 'Country Owner',
    USER_ROLE_REGULAR: 'Regular',
    USER_ROLE_INVALID: 'Inactive',
    WITHDRAWALS: 'Withdrawals',

    /**
     * Autocomplete
     */
    AUTOCOMPLETE_NO_OPTIONS: 'There are no options for this search.',
    AUTOCOMPLETE_INSTRUCTIONS: 'Start to write to search.',

    /**
     * Validation
     */
    VALIDATE_EMAIL: 'The email you entered is not valid.',
    VALIDATE_REQUIRED_GENERIC: 'This field is required.',
    VALIDATE_REQUIRED: '{fieldName} is a required field.',
    VALIDATE_PASSWORD_NOT_NEW:
      'The new password must be different than your current password.',
    VALIDATE_PASSWORD_MATCH: 'Passwords must match.',
    VALIDATE_PASSWORD_INSTRUCTIONS:
      'Must have at least {length} characters, a number, a lower case letter, an upper case letter and a symbol (. @ $ ! % * # ? & -)',
    VALIDATE_CREDENTIALS_ERROR: 'Incorrect username or password.',
    VALIDATE_CURRENT_PASSWORD: 'The current password is incorrect.',
    VALIDATE_MUST_SELECT_SCOPE: 'You must select a scope.',

    ERROR_FETCHING_COLLABORATOR: `We are sorry! There was an error downloading the collaborator's information`,
    ERROR_INTERVIEW_EXISTS:
      'The interview for the collaborator with Sharp ID {sharpId}, already exists in the system.',
    ERROR_INTERVIEW_OUT_OF_SCOPE:
      'You cannot create an interview for a collaborator that is out of your scope (Different country, BU, etc...).',
    ERROR_CREATING_INTERVIEW:
      'We are sorry! There was an error creating the interview',
    ERROR_FETCHING_STORED_QUESTIONARY:
      'We are sorry! There was an error downloading the stored information about the interview.',
    ERROR_SAVING_PARTIAL_QUESTIONARY:
      'We are sorry! There was an error while saving the partial interview.',
    ERROR_SAVING_QUESTIONS:
      'We are sorry! There was an error while saving the questions.',
    ERROR_FETCHING_STORED_FEEDBACK:
      'We are sorry! There was an error while downloading the stored feedback for the interview.',
    ERROR_SAVING_PARTIAL_FEEDBACK:
      'We are sorry! There was an error while saving the partial feedback.',
    ERROR_SAVING_FEEDBACK:
      'We are sorry! There was an error while saving the feedback.',
    ERROR_DOWNLOADING_REPORT:
      '¡Lo sentimos! Hubo un error descargando el reporte.',
    ERROR_DROPZONE_FILE_TOO_LARGE:
      'The file that you are trying to upload is exceeds {fileSize}, please verify the file or contact support.',

    SUCCESS_CREATING_INTERVIEW: 'The interview has been created correctly.',
    SUCCESS_SAVING_QUESTIONS: 'The questions has been correctly saved.',
    SUCCESS_SAVING_FEEDBACK: 'The feedback has been correctly saved.',

    BUSINESS_UNIT_CAC: 'CAC',
    BUSINESS_UNIT_CO: 'Colombia',
    BUSINESS_UNIT_EC: 'Ecuador',
    'BUSINESS_UNIT_HN / SV': 'Honduras / Salvador',
    BUSINESS_UNIT_MEX: 'Mexico',
    BUSINESS_UNIT_PE: 'Peru',

    VP_SALES: 'Sales',
    VP_FINANCE: 'Finance',
    'VP_LEGAL / CORPORATE AFFAIRS': 'Legal',
    VP_LOGISTICS: 'Logistics',
    VP_SUPPLY: 'Supply',
    VP_SOLUTIONS: 'Solutions',
    VP_MARKETING: 'Marketing',
    VP_PROCUREMENT: 'Procurement',
    VP_PEOPLE: 'People',

    COMMENTS: 'Comments',
    BOARD: 'Dashboard',
    ADMIN_COLUMN_DH_ACCESS: 'Dashboard Access',
    MESSAGE_CRITICAL: '<p>If you indentified a situation harassment, discrimination or violence againts to the ex employee during your laboral life in ABI. It\'s important to select as <strong>CRITICAL</strong> option in the interview.</p> <p>But if not apply these situation, don\'t select as <strong>CRITICAL</strong> the interview because the tool will send the notification to People Heads to analize detail of each case and they will be in contact with you for more details.</p>'
  },
};

const keys = {};
export const TranslationKey = Object.keys(translations[Locale.Spanish]).reduce(
  (obj: typeof keys, key: string): typeof keys => ({
    ...obj,
    [key]: key,
  }),
  keys,
) as {
  [key in keyof typeof translations[Locale.Spanish]]: string;
};

export default translations;
