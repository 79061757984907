import DateFnsUtils from '@date-io/date-fns';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Tooltip,
  TooltipProps,
} from '@material-ui/core';
import { Help as HelpIcon, Today as TodayIcon } from '@material-ui/icons';
import {
  DatePicker,
  DatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { useField } from 'formik';
import React, {
  FunctionComponent,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { Locale } from '../../enums';
import { useDateFormatString } from '../../hooks';
import { userSessionSelectors } from '../../selectors';

const localeMap = {
  [Locale.Spanish]: esLocale,
  [Locale.English]: enLocale,
};

interface InputProps {
  value?: string;
  id: string;
  fullWidth: boolean;
  required: boolean;
  placeholder: string;
  endAdornment: ReactNode;
}

interface FormDateFieldProps extends Partial<DatePickerProps> {
  name: string;
  label: string;
  required?: boolean;
  loading?: boolean;
  tooltipProps?: Partial<TooltipProps>;
}

const DateField: FunctionComponent<FormDateFieldProps> = ({
  name,
  label,
  placeholder,
  required = false,
  loading = false,
  disabled,
  tooltipProps,
  ...props
}): ReactElement => {
  const locale = useSelector(userSessionSelectors.getLocale);
  const dateFormat = useDateFormatString();

  const [field, meta, helpers] = useField<Date | null>(name);
  const handleChange = useCallback(
    (date: Date | null): void => {
      helpers.setValue(date);
    },
    [helpers],
  );

  const hasError = meta.touched && !!meta.error;

  const inputProps: InputProps = {
    value: '',
    id: 'app-text-field',
    fullWidth: true,
    required,
    placeholder: placeholder || label,
    endAdornment: loading ? (
      <InputAdornment position="end">
        <CircularProgress size="20px" thickness={5} color="primary" />
      </InputAdornment>
    ) : (
      <IconButton size="small" disabled={disabled}>
        <TodayIcon />
      </IconButton>
    ),
  };
  // Ensures the input correctly formats the string
  if (field.value) delete inputProps.value;

  const handleTooltipClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
    },
    [],
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
      <DatePicker
        {...field}
        {...props}
        name={name}
        label={
          <>
            {label}
            {tooltipProps && (
              <Tooltip title={tooltipProps.title || ''} {...tooltipProps}>
                <IconButton
                  size="small"
                  style={{
                    position: 'absolute',
                    top: -4,
                    right: -22,
                    fontSize: 16,
                  }}
                  onClick={handleTooltipClick}
                >
                  <HelpIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
        disabled={disabled}
        onChange={handleChange}
        required={required}
        format={dateFormat}
        size="small"
        inputVariant="outlined"
        fullWidth
        clearable
        error={hasError}
        helperText={hasError ? meta.error : ' '}
        InputLabelProps={{
          shrink: false,
        }}
        InputProps={inputProps}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateField;
