import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner';
import { userSessionActions } from '../../actions';
import styles from './Session.module.scss';

const Callback = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const state = sessionStorage.getItem("state");

        if (params.state === state) {
            dispatch(
                userSessionActions.loginUserRequest(params?.code, params?.state)
            )
        }
    }, [dispatch]);
    return (
        <div className={styles.fullSpinnerContainer}>
            <LoadingSpinner />
        </div>
    );
};

export default Callback;
